export enum EAdminUrl {
  ADMIN = 'admin/api/v2',
  PROPERTY_MANAGEMENT = 'property-management',
  HUB_SPOT = 'hub-spot',
  DISABLE = 'disable',
  ENABLE = 'enable',
  NOT_SYNCED_ANIMALS_COUNT = 'not-synced-animals-count',
  ANALYTICS = 'analytics',
  UNITS = 'units',
  PROFILE = 'profile',
  PROFILE_PICTURE = 'profile-picture',
  PLAN = 'plan',
  COURSES = 'courses',
}
