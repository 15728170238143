import {
  analyticsLandlordsQueryConverter,
  propertyManagementEmailsExportConverter,
  updateBusinessNameConverter,
} from 'antd/data/converters/landlord.converter';
import {
  TPMEmailsExportUI,
  TUpdateBusinessNameUI,
} from 'antd/data/types/landlord.types';
import { EPropertyManagementUrl } from 'antd/data/urls/propertyManagement.url';
import { baseUrlsV2 } from 'api/services/endpointEnums';
import { selectOptionConverter } from 'antd/data/converters/general.converters';
import { TOptionTypeUi } from 'antd/data/types/general.types';
import { TOptionsType } from 'antd/components/Select';
import { EAdminUrl } from 'antd/data/urls/admin.url';
import { downloadFile } from 'antd/api/sevices/fileServices';
import { authSplitApi } from '../helpers/slice.helpers';

const { PROPERTY_MANAGER, ADMIN } = baseUrlsV2;
export const propertyManagementApi = authSplitApi('propertyManagementApi', [
  'pmPositions',
]).injectEndpoints({
  endpoints: (build) => ({
    updateBusinessName: build.mutation<void, TUpdateBusinessNameUI>({
      query(data) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGEMENT}/${EPropertyManagementUrl.NAME}`,
          method: 'PATCH',
          body: updateBusinessNameConverter.toDb(data),
        };
      },
    }),
    getPropertyManagements: build.query<
      TOptionsType,
      { statuses: TOptionTypeUi[] }
    >({
      query({ statuses }) {
        return {
          url: `${ADMIN}/${EPropertyManagementUrl.PROPERTY_MANAGEMENT}/${EPropertyManagementUrl.ALL}`,
          method: 'GET',
          params: statuses.length
            ? analyticsLandlordsQueryConverter.toDb(statuses)
            : undefined,
        };
      },
      transformResponse: (data: TOptionsType) =>
        (data || []).map((pm) => selectOptionConverter.fromDb(pm)),
    }),
    getPropertyManagerPositions: build.query<TOptionsType, void>({
      query() {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGERS}/${EPropertyManagementUrl.POSITION}`,
          method: 'GET',
        };
      },
      providesTags: ['pmPositions'],
      transformResponse: (data: TOptionsType) =>
        (data || []).map((pm) => selectOptionConverter.fromDb(pm)),
    }),
    getEmails: build.query<string, TPMEmailsExportUI>({
      query(params) {
        return {
          url: `${EAdminUrl.ADMIN}/${EPropertyManagementUrl.PROPERTY_MANAGEMENT}/${EPropertyManagementUrl.EXPORT}/${EPropertyManagementUrl.PM_EMAILS}`,
          method: 'GET',
          params: propertyManagementEmailsExportConverter.toDb(params),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'pmEmails.csv');
            return null;
          },
        };
      },
    }),
    petChargingInfoExport: build.query<string, TOptionTypeUi[]>({
      query(propertyIds) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGEMENT}/${EPropertyManagementUrl.PET_CHARGING_INFO_PET}/${EPropertyManagementUrl.EXPORT}`,
          method: 'GET',
          params: { propertyIds: propertyIds.map((item) => item.value) },
          cache: 'no-cache',
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'No_Charged_Pets_Report', 'xls');
            return null;
          },
        };
      },
    }),
    updatePropertyManagerProfilePicture: build.mutation<
      void,
      { profilePicture: string }
    >({
      query(body) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGERS}/${EPropertyManagementUrl.PROFILE_PICTURE}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    updatePropertyManagerPhone: build.mutation<
      void,
      { phone: string; propertyManagerId: string }
    >({
      query(body) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGERS}/${EPropertyManagementUrl.PHONE}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    updatePropertyManagerName: build.mutation<
      void,
      { name: string; propertyManagerId: string }
    >({
      query(body) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGERS}/${EPropertyManagementUrl.NAME}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    updatePropertyManagerPosition: build.mutation<
      void,
      { position: { id?: string; value?: string }; propertyManagerId: string }
    >({
      query(body) {
        return {
          url: `${PROPERTY_MANAGER}/${EPropertyManagementUrl.PROPERTY_MANAGERS}/${EPropertyManagementUrl.POSITION}`,
          method: 'PATCH',
          body,
        };
      },
    }),
  }),
});

export const {
  useUpdateBusinessNameMutation,
  useGetPropertyManagerPositionsQuery,
  useGetPropertyManagementsQuery,
  useLazyGetEmailsQuery,
  useLazyPetChargingInfoExportQuery,
  useUpdatePropertyManagerProfilePictureMutation,
  useUpdatePropertyManagerNameMutation,
  useUpdatePropertyManagerPhoneMutation,
  useUpdatePropertyManagerPositionMutation,
} = propertyManagementApi;
