import { b64toBlob } from 'antd/api/sevices/fileServices';
import { formatStateLabel, formattedDateTimezone } from 'antd/helpers/utils';
import { TAuthConverter, TConvertor, TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  EIntegrationType,
  EIntegrationTypeDB,
  ESycnPeriodicityDB,
  ESycnPeriodicityUI,
  TConnectYardiIntegrationDB,
  TConnectYardiIntegrationUI,
  TIntegrationDataExportDB,
  TIntegrationDataExportUI,
  TIntegrationDataUI,
  TIntegrationExecutionResultProps,
  TIntegrationResultProps,
  TIntegrationResultPropsUI,
  TNotChargedPetsReportScheduleGetDB,
  TNotChargedPetsReportScheduleGetUI,
  TNotChargedPetsReportScheduleSettingsDB,
  TNotChargedPetsReportScheduleSettingsUI,
  TPetChargeSyncEnableValidateDB,
  TPetChargeSyncEnableValidateUI,
  TPropertyForMatchDB,
  TPropertyForMatchUI,
  TPropertySyncStateDB,
  TPropertySyncStateUI,
  TPUploadSyncUI,
  TRealPageIntegrationSettingsDB,
  TRealPageIntegrationSettingsUI,
  TResidentsPetChargesUploadDataUI,
  TResmanConnectDB,
  TResmanConnectUI,
  TResmanIntegrationDB,
  TResmanIntegrationUI,
  TResmanPropertiesDB,
  TResmanPropertiesUI,
  TResmanPropertyMatchUI,
  TSycnScheduleDB,
  TSycnScheduleUI,
  TSyncPeriodicityDB,
  TSyncPeriodicityUI,
  TYardiChargeCodesDB,
  TYardiChargeCodesUI,
  TYardiIntegrationDB,
  TYardiIntegrationUI,
  TYardiPropertyDB,
  TYardiPropertyMatchDB,
  TYardiPropertyMatchUI,
  TYardiPropertyUI,
  TYardiVersionsDB,
  TYardiVersionsUI,
} from '../types/integration.types';
import { selectOptionConverter, yesNoOptionConverter } from './general.converters';

const integrationResultConverter: TFetchConverter<TIntegrationResultPropsUI, TIntegrationResultProps> = {
  fromDb: (data) => {
    return {
      numberOfNewApplicantAnimals: data.numberOfNewApplicantAnimals,
      numberOfFoundMovedOutTenants: data.numberOfFoundMovedOutTenants,
      numberOfFoundMovedOutAnimals: data.numberOfFoundMovedOutAnimals,
      foundMovedOutAnimalsFile: data.foundMovedOutAnimalsFile
        ? b64toBlob(data.foundMovedOutAnimalsFile, 'text/csv')
        : null,
      foundMovedOutTenantsFile: data.foundMovedOutTenantsFile
        ? b64toBlob(data.foundMovedOutTenantsFile, 'text/csv')
        : null,
      applicantsAnimals: data.applicants?.numberOfAnimals,
      applicantsResidencies: data.applicants?.numberOfResidencies,
      applicantAnimalsFile: data.applicantAnimalsFile ? b64toBlob(data.applicantAnimalsFile, 'text/csv') : null,
      applicantsFile: data.applicantsFile ? b64toBlob(data.applicantsFile, 'text/csv') : null,
      movedInResidencies: data.moveInResidencies.numberOfResidencies,
      movedInTotalAnimals: data.moveInResidencies.numberOfAnimals + data.numberOfNewAnimals,
      movedInAnimals: data.moveInResidencies.numberOfAnimals,
      numberOfNewAnimals: data.numberOfNewAnimals,
      movedOutTotalResidencies:
        data.moveOutResidencies.numberOfResidencies + data.terminatingMissingPropertyResidences.numberOfResidencies,
      movedOutResidencies: data.moveOutResidencies.numberOfResidencies,
      movedOutAnimals: data.moveOutResidencies.numberOfAnimals,
      movedOutResidenciesFromMissingProperty: data.terminatingMissingPropertyResidences.numberOfResidencies,
      numberOfMissingProperties: data.terminatingMissingPropertyResidences.numberOfProperties,
      numberOfMatchedProperties: data.numberOfMatchedProperties,
      numberOfSkippedResidencies: data.skippedNotFoundPropertyResidences.numberOfResidencies,
      numberOfSkippedProperties: data.skippedNotFoundPropertyResidences.numberOfProperties,
      numberOfAnimalsSkippedAsDuplicate: data.numberOfAnimalsSkippedAsDuplicate,
      skippedResidenciesCountBecauseOfTenantDuplication: data.skippedResidenciesCountBecauseOfTenantDuplication,
      numberOfResidenciesToMove: data.numberOfResidenciesToMove,
      numberOfApplicantsToMove: data.numberOfApplicantsToMove,
      numberOfSkippedApplicantsProperties: data.skippedNotFoundPropertyApplications?.numberOfProperties || 0,
      numberOfSkippedApplicants: data.skippedNotFoundPropertyResidences?.numberOfApplicants || 0,
      moveInResidenciesFile: data.moveInResidenciesFile ? b64toBlob(data.moveInResidenciesFile, 'text/csv') : null,
      moveInAnimalsFile: data.moveInAnimalsFile ? b64toBlob(data.moveInAnimalsFile, 'text/csv') : null,
      moveOutFile: data.moveOutFile ? b64toBlob(data.moveOutFile, 'text/csv') : null,
      moveResidenciesFile: data.moveResidenciesFile ? b64toBlob(data.moveResidenciesFile, 'text/csv') : null,
      numberOfPropertiesToOnboard: data.numberOfPropertiesToOnboard,
      numberOfResidenciesToTagAsKnown: data.numberOfResidenciesToTagAsKnown,
      numberOfAnimalsToTagAsKnown: data.numberOfAnimalsToTagAsKnown,
    };
  },
};
export const uploadCSVPreviewConverter: TAuthConverter<
  TIntegrationDataUI,
  FormData,
  TIntegrationResultPropsUI,
  TIntegrationResultProps
> = {
  fromDb: (data) => {
    return integrationResultConverter.fromDb(data);
  },
  toDb: (data) => {
    const formData = new FormData();

    formData.append('type', data.type.value);
    formData.append('policyId', data.policy.value);
    formData.append('configuration.shouldConsiderMoveOut', data.shouldConsiderMoveOut);
    if (data.shouldConsiderMissingProperties) {
      formData.append('configuration.shouldConsiderMissingProperties', data.shouldConsiderMissingProperties);
    }
    data.csvFile.forEach((item) => {
      formData.append('csvFile', item);
    });
    return formData;
  },
};

export const uploadTenantsPetChargesImportConverter: TCreateConverter<TResidentsPetChargesUploadDataUI, FormData> = {
  toDb: (data) => {
    const formData = new FormData();
    formData.append('skipMovedOutTenantsAndAnimals', (!data.skipMovedOutTenantsAndAnimals)?.toString());
    formData.append('importType', data.importType.value);
    formData.append('propertyId', data.propertyId);
    formData.append('configuration.shouldConsiderMoveOut', data.shouldConsiderMoveOut);
    if (data.shouldConsiderMissingProperties) {
      formData.append('configuration.shouldConsiderMissingProperties', data.shouldConsiderMissingProperties);
    }
    data.tenantsFile.forEach((item) => {
      formData.append('tenantsFile', item);
    });
    data.petChargesFile.forEach((item) => {
      formData.append('petChargesFile', item);
    });
    return formData;
  },
};

export const uploadTenantsPetChargesCSVPreviewConverter: TAuthConverter<
  TResidentsPetChargesUploadDataUI,
  FormData,
  TIntegrationResultPropsUI,
  TIntegrationResultProps
> = {
  fromDb: (data) => {
    return integrationResultConverter.fromDb(data);
  },
  toDb: (data) => {
    return uploadTenantsPetChargesImportConverter.toDb(data);
  },
};

export const uploadTenantsPetChargesCSVExecuteConverter: TCreateConverter<TResidentsPetChargesUploadDataUI, FormData> =
  {
    toDb: (data) => {
      return uploadTenantsPetChargesImportConverter.toDb(data);
    },
  };
export const uploadCSVExecuteConverter: TAuthConverter<
  TIntegrationDataUI,
  FormData,
  TIntegrationExecutionResultProps,
  TIntegrationExecutionResultProps
> = {
  fromDb: (data) => ({
    matchedPropertiesMoveInResidencies: data.matchedPropertiesMoveInResidencies,
    matchedPropertiesMoveOutResidencies: data.matchedPropertiesMoveOutResidencies,
    terminatingMissingPropertyResidences: data.terminatingMissingPropertyResidences,
  }),
  toDb: (data) => {
    const formData = new FormData();
    formData.append('skipMovedOutTenantsAndAnimals', (!data.skipMovedOutTenantsAndAnimals)?.toString());
    formData.append('type', data.type.value);
    formData.append('policyId', data.policy.value);
    formData.append('configuration.shouldConsiderMoveOut', data.shouldConsiderMoveOut);
    if (data.shouldConsiderMissingProperties) {
      formData.append('configuration.shouldConsiderMissingProperties', data.shouldConsiderMissingProperties);
    }
    data.csvFile.forEach((item) => {
      formData.append('csvFile', item);
    });
    return formData;
  },
};

export const syncPeriodicityUnitConverter: TConvertor<ESycnPeriodicityUI, ESycnPeriodicityDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in ESycnPeriodicityDB]: ESycnPeriodicityUI } = {
      [ESycnPeriodicityDB.DAILY]: ESycnPeriodicityUI.DAILY,
      [ESycnPeriodicityDB.HOURS]: ESycnPeriodicityUI.HOURS,
      [ESycnPeriodicityDB.MINUTES]: ESycnPeriodicityUI.MINUTES,
      [ESycnPeriodicityDB.MONTHLY]: ESycnPeriodicityUI.MONTHLY,
      [ESycnPeriodicityDB.WEEKLY]: ESycnPeriodicityUI.WEEKLY,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in ESycnPeriodicityUI]: ESycnPeriodicityDB } = {
      [ESycnPeriodicityUI.DAILY]: ESycnPeriodicityDB.DAILY,
      [ESycnPeriodicityUI.HOURS]: ESycnPeriodicityDB.HOURS,
      [ESycnPeriodicityUI.MINUTES]: ESycnPeriodicityDB.MINUTES,
      [ESycnPeriodicityUI.MONTHLY]: ESycnPeriodicityDB.MONTHLY,
      [ESycnPeriodicityUI.WEEKLY]: ESycnPeriodicityDB.WEEKLY,
    };

    return dataMapper[data];
  },
};

export const yardiPropertyConvertor: TFetchConverter<TYardiPropertyUI, TYardiPropertyDB> = {
  fromDb: (data) => {
    return {
      policyId: data.policyId,
      canPlanSync: data.canPlanSync,
      notChargedPetsSyncEnabled: data.notChargedPetsSyncEnabled,
      id: data.id,
      name: data.name,
      propertyMatched: data.propertyMatched,
      state: formatStateLabel(data.state),
      unitsCount: data.unitsCount,
      isSyncEnabled: data.applicantsSyncEnabled || data.residentsSyncEnabled,
      applicantsSyncEnabled: data.applicantsSyncEnabled,
      residentsSyncEnabled: data.residentsSyncEnabled,
      followUpTenantsWithAnimalStatusNo: yesNoOptionConverter.fromDb(data.followUpTenantsWithAnimalStatusNo),
      internalPolicyId: data.internalPolicyId,
      city: data.city,
      address1: data.address1,
      zip: data.zip,
      existingApplicantsLastSyncTime: data.existingApplicantsLastSyncTime
        ? formattedDateTimezone(data.existingApplicantsLastSyncTime, 'MM/DD/YYYY [at] hh:mm A')
        : undefined,
      newApplicantsLastSyncTime: data.newApplicantsLastSyncTime
        ? formattedDateTimezone(data.newApplicantsLastSyncTime, 'MM/DD/YYYY[at] hh:mm A')
        : undefined,
      notChargedPetsSyncLastSyncDateTime: data.notChargedPetsSyncLastSyncDateTime
        ? formattedDateTimezone(data.notChargedPetsSyncLastSyncDateTime, 'MM/DD/YYYY[at] hh:mm A')
        : undefined,
      residentsLastSyncTime: data.residentsLastSyncTime
        ? formattedDateTimezone(data.residentsLastSyncTime, 'MM/DD/YYYY [at] hh:mm A')
        : undefined,
      matchedProperty: data.matchedProperty,
    };
  },
};
export const yardiSyncPeriodicityTextConvertor: TFetchConverter<string | undefined, TSyncPeriodicityDB> = {
  fromDb(data) {
    return data.unit === ESycnPeriodicityDB.MINUTES
      ? `Every ${data.quantity} minutes`
      : data.unit === ESycnPeriodicityDB.HOURS
      ? `Every ${data.quantity} hours`
      : undefined;
  },
};

export const yardiSyncPeriodicityConvertor: TFetchConverter<TSyncPeriodicityUI, TSyncPeriodicityDB> = {
  fromDb: (data) => {
    return {
      unit: syncPeriodicityUnitConverter.fromDb(data.unit),
      quantity: data.quantity,
      previewText: yardiSyncPeriodicityTextConvertor.fromDb(data),
    };
  },
};

export const yardiVersionsConverter: TFetchConverter<TYardiVersionsUI, TYardiVersionsDB> = {
  fromDb: (data) => {
    return {
      coreInterfaceVersion: data.coreInterfaceVersion,
      pluginVersion: data.pluginVersion,
      voyagerVersion: data.voyagerVersion,
    };
  },
};
export const notChargedPetsReportScheduleSettingsConvertor: TCreateConverter<
  TNotChargedPetsReportScheduleSettingsUI,
  TNotChargedPetsReportScheduleSettingsDB
> = {
  toDb: (data) => {
    return {
      periodicity: {
        unit: syncPeriodicityUnitConverter.toDb(data.unit.value),
        day: data.day.value,
        hour: Number(data.hour.value),
      },
      positionIds: data.positionIds.map((item) => item.value),
    };
  },
};

export const notChargedPetsReportScheduleGetConvertor: TFetchConverter<
  TNotChargedPetsReportScheduleGetUI,
  TNotChargedPetsReportScheduleGetDB
> = {
  fromDb: (data) => {
    return {
      unit: {
        value: syncPeriodicityUnitConverter.fromDb(data.periodicity.unit),
        label: syncPeriodicityUnitConverter.fromDb(data.periodicity.unit),
      },
      day: data.periodicity.day,
      hour: data.periodicity.hour,
      positionIds: data.position.map((item) => selectOptionConverter.fromDb(item)),
    };
  },
};

export const yardiIntegrationInfoConvertor: TFetchConverter<TYardiIntegrationUI, TYardiIntegrationDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      isTestMode: data.isTestMode,
      canConnect: data.canConnect,
      database: data.database || '',
      password: data.password || '',
      username: data.username || '',
      baseUrl: data.baseUrl || '',
      petFeeChargeCodes: data.petFeeChargeCodes?.filter((item) => !!item),
      petRentChargeCodes: data.petRentChargeCodes?.filter((item) => !!item),
      applicantsUpdateSyncPeriodicity: data.applicantsUpdateSyncPeriodicity
        ? yardiSyncPeriodicityConvertor.fromDb(data.applicantsUpdateSyncPeriodicity)
        : null,
      newApplicantsSyncPeriodicity: data.newApplicantsSyncPeriodicity
        ? yardiSyncPeriodicityConvertor.fromDb(data.newApplicantsSyncPeriodicity)
        : null,
      residentSyncPeriodicity: data.residentSyncPeriodicity
        ? yardiSyncPeriodicityConvertor.fromDb(data.residentSyncPeriodicity)
        : null,
      residentDataVersion: data.residentDataVersion ? yardiVersionsConverter.fromDb(data.residentDataVersion) : null,
      ilgGuestCardVersion: data.ilgGuestCardVersion ? yardiVersionsConverter.fromDb(data.ilgGuestCardVersion) : null,
      ...(data.notChargedPetsReportSchedules && {
        notChargedPetsReportScheduleSettings: data.notChargedPetsReportSchedules.map((item) =>
          notChargedPetsReportScheduleGetConvertor.fromDb(item)
        ),
      }),
    };
  },
};

export const yardiIntegrationSyncPeriodicityConvertor: TCreateConverter<TSycnScheduleUI, TSycnScheduleDB> = {
  toDb: (data) => {
    return {
      residentSyncPeriodicity: {
        unit: syncPeriodicityUnitConverter.toDb(
          data.residents.value === 'custom'
            ? (data.residentsUnit?.label as ESycnPeriodicityUI)
            : (data.residents?.label as ESycnPeriodicityUI)
        ),
        quantity: Number(data.residentsQuantity?.value) || 1,
      },
      applicantsUpdateSyncPeriodicity: {
        unit: syncPeriodicityUnitConverter.toDb(
          data.applicantsUpdate.value === 'custom'
            ? (data.applicantsUpdateUnit?.label as ESycnPeriodicityUI)
            : (data.applicantsUpdate?.label as ESycnPeriodicityUI)
        ),
        quantity: Number(data.applicantsUpdateQuantity?.value) || 1,
      },
      newApplicantsSyncPeriodicity: {
        unit: syncPeriodicityUnitConverter.toDb(
          data.newApplicants.value === 'custom'
            ? (data.newApplicantsUnit?.label as ESycnPeriodicityUI)
            : (data.newApplicants?.label as ESycnPeriodicityUI)
        ),
        quantity: Number(data.newApplicantsQuantity?.value) || 1,
      },
      ...(data.notChargedPetsReportScheduleSettings && {
        notChargedPetsReportScheduleSettings: data.notChargedPetsReportScheduleSettings.map((item) =>
          notChargedPetsReportScheduleSettingsConvertor.toDb(item)
        ),
      }),
    };
  },
};

export const yardiPropertyMatchConverter: TCreateConverter<TYardiPropertyMatchUI, TYardiPropertyMatchDB> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      linkedPropertyId: data.newPropertyId || data.matchedProperty.value,
      applicantsEnabled: !!data.applicantsEnabled,
      residentsEnabled: !!data.residentsEnabled,
      notChargedPetsEnabled: !!data.notChargedPetsEnabled,
      followUpTenantsWithAnimalStatusNo: data.followUpTenantsWithAnimalStatusNo === 'Yes',
    };
  },
};

export const yardiNotMatchedProperties: TFetchConverter<TPropertyForMatchUI, TPropertyForMatchDB> = {
  fromDb: (data) => {
    return {
      defaultPolicy: data.hasAssignedPolicy,
      label: data.name,
      value: data.id,
      policyId: data.policyId,
      internalPolicyId: data.internalPolicyId,
    };
  },
};

export const yardiPropertySyncStatus: TCreateConverter<TPropertySyncStateUI, TPropertySyncStateDB> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      notChargedPetsEnabled: data.notChargedPetsEnabled || false,
      applicantsEnabled: data.applicantsEnabled || false,
      residentsEnabled: data.residentsEnabled || false,
      followUpTenantsWithAnimalStatusNo:
        data.followUpTenantsWithAnimalStatusNo && yesNoOptionConverter.toDb(data.followUpTenantsWithAnimalStatusNo),
    };
  },
};

export const resmanIntegrationConverter: TFetchConverter<TResmanIntegrationUI, TResmanIntegrationDB> = {
  fromDb: (data) => {
    return {
      accountId: data.accountId || undefined,
      canConnect: !!data.canConnect,
      id: data.id || undefined,
      syncPeriodicity:
        (data.syncPeriodicity && {
          value: data.syncPeriodicity.unit,
          label: data.syncPeriodicity.unit.charAt(0) + data.syncPeriodicity.unit.slice(1).toLowerCase(),
        }) ||
        undefined,
    };
  },
};

export const resmanIntegrationConnectConverter: TCreateConverter<TResmanConnectUI, TResmanConnectDB> = {
  toDb: (data) => {
    return {
      resManId: data.resManId,
      syncPeriodicity: data.syncPeriodicity && {
        unit: data.syncPeriodicity.value,
        quantity: 1,
      },
      settingsId: data.settingsId,
    };
  },
};

export const resmanPropertyConvertor: TFetchConverter<TResmanPropertiesUI, TResmanPropertiesDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      canPlanSync: data.canPlanSync,
      name: data.name,
      address1: data.address1,
      city: data.city,
      unitsCount: data.unitCount,
      type: data.type,
      status: data.status,
      isStateSupported: data.isStateSupported,
      residentsLastSyncTime: data.lastSyncDate
        ? formattedDateTimezone(data.lastSyncDate, 'MM/DD/YYYY [at] HH:mm A')
        : undefined,
      policy: data.policy,
      state: data.state.charAt(0) + data.state.slice(1).toLowerCase(),
      isSyncEnabled: data.enabled,
      propertyMatched: data.propertyMatched,
      residentsSyncEnabled: data.enabled,
      matchedProperty: data.matchedProperty,
    };
  },
};
export const resmanPropertyMatchConverter: TCreateConverter<TYardiPropertyMatchUI, TResmanPropertyMatchUI> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      linkedPropertyId: data.newPropertyId || data.matchedProperty.value,
      residentsEnabled: !!data.residentsEnabled,
    };
  },
};

export const resmanPropertySycnConverter: TCreateConverter<TPropertySyncStateUI, TPropertySyncStateDB> = {
  toDb: (data) => {
    return {
      propertyId: data.propertyId,
      residentsEnabled: !!data.residentsEnabled,
    };
  },
};

export const connectYardiFormConverter: TCreateConverter<TConnectYardiIntegrationUI, TConnectYardiIntegrationDB> = {
  toDb: (data) => {
    return {
      username: data.username,
      password: data.password,
      database: data.database,
      baseUrl: data.baseUrl,
      isTestMode: !!data.isTestMode,
      petRentChargeCodes: data.petRentChargeCodes?.filter((item) => !!item),
      petFeeChargeCodes: data.petFeeChargeCodes?.filter((item) => !!item),
    };
  },
};
export const petChargeStatusSyncEnableValidationConvertor: TFetchConverter<
  TPetChargeSyncEnableValidateUI,
  TPetChargeSyncEnableValidateDB
> = {
  fromDb: ({ chargeCodeInfo, policyInfo }) => {
    let chargeCodesErrorStr = '';
    const isPolicyError = !policyInfo || (!policyInfo?.petFeeChargeAmount && !policyInfo?.petRentChargeAmount);
    if (
      policyInfo?.petFeeChargeAmount &&
      policyInfo?.petRentChargeAmount &&
      !chargeCodeInfo?.petRentChargeCodes.length &&
      !chargeCodeInfo?.petFeeChargeCodes.length
    ) {
      chargeCodesErrorStr = 'Pet Rent and Pet Fee Charge Codes must be provided!';
    } else if (policyInfo?.petRentChargeAmount && !chargeCodeInfo?.petRentChargeCodes.length) {
      chargeCodesErrorStr = 'Pet Rent Charge Code must be provided!';
    } else if (policyInfo?.petFeeChargeAmount && !chargeCodeInfo?.petFeeChargeCodes.length) {
      chargeCodesErrorStr = 'Pet Fee Charge Code must be provided!';
    }
    return {
      canEnable: !isPolicyError && !chargeCodesErrorStr,
      isPolicyError,
      chargeCodesErrorStr: !isPolicyError ? chargeCodesErrorStr : '',
      chargeCodeInfo: {
        petFeeChargeCodes: chargeCodeInfo?.petFeeChargeCodes?.filter((item) => !!item),
        petRentChargeCodes: chargeCodeInfo?.petRentChargeCodes?.filter((item) => !!item),
      },
      policyInfo: {
        petFeeChargeAmount: policyInfo?.petFeeChargeAmount,
        petRentChargeAmount: policyInfo?.petRentChargeAmount,
      },
    };
  },
};

export const integrationDataExportConverter: TCreateConverter<TIntegrationDataExportUI, TIntegrationDataExportDB> = {
  toDb: ({ integrationType, propertyManagementId, propertyId }) => {
    return {
      ...(integrationType === 'Yardi' && { yardiPropertyId: propertyId }),
      ...(integrationType === 'Resman' && { resManPropertyId: propertyId }),
      propertyManagementId,
    };
  },
};

export const petChargesCodesUpdateConverter: TCreateConverter<TYardiChargeCodesUI, TYardiChargeCodesDB> = {
  toDb: ({ petFeeChargeCodes, petRentChargeCodes }) => {
    return {
      petFeeChargeCodes: petFeeChargeCodes?.filter((item) => !!item),
      petRentChargeCodes: petRentChargeCodes?.filter((item) => !!item),
    };
  },
};
export const realPageConnectionSettingsConverter: TFetchConverter<
  TRealPageIntegrationSettingsUI,
  TRealPageIntegrationSettingsDB
> = {
  fromDb: (data) => {
    return {
      id: data.id,
      canConnect: data.canConnect,
      isConnected: data.isConnected,
      lastUploadDate: data.lastUploadDate ? formattedDateTimezone(data.lastUploadDate) : undefined,
    };
  },
};

export const tpUploadSyncConverter: TCreateConverter<TPUploadSyncUI, FormData> = {
  toDb: (data) => {
    const formData = new FormData();

    formData.append('type', data.type);
    data.zipFile.forEach((item) => {
      formData.append('zipFile', item);
    });
    return formData;
  },
};

export const integrationTypeConverter: TConvertor<EIntegrationType, EIntegrationTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EIntegrationTypeDB]: EIntegrationType } = {
      [EIntegrationTypeDB.REAL_PAGE]: EIntegrationType.REAL_PAGE,
      [EIntegrationTypeDB.RESMAN]: EIntegrationType.RESMAN,
      [EIntegrationTypeDB.YARDI]: EIntegrationType.YARDI,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EIntegrationType]: EIntegrationTypeDB } = {
      [EIntegrationType.REAL_PAGE]: EIntegrationTypeDB.REAL_PAGE,
      [EIntegrationType.RESMAN]: EIntegrationTypeDB.RESMAN,
      [EIntegrationType.YARDI]: EIntegrationTypeDB.YARDI,
    };

    return dataMapper[data];
  },
};
