import { TYesNoOptionType } from './generalTypes';

export enum EUserRolesDB {
  ADMIN = 'admin',
  LANDLORD = 'landlord',
  PROPERTY_MANAGER = 'propertyManager',
}

export enum EUserRolesUI {
  ADMIN = 'admin',
  LANDLORD = 'landlord',
  PROPERTY_MANAGER = 'propertyManager',
}

export type TImpersonatedOnUI = {
  userId: string;
  name: string;
};

export type TImpersonatedOnDB = {
  userId: string;
  name: string;
};
export type TUserAdminDB = {
  userId: string;
  name: string;
  username: string;
  email: string;
  phone: string | number;
  impersonateOn: TImpersonatedOnDB | null;
};

export type TUserAdminUI = {
  id: string;
  name: string;
  username: string;
  email: string;
  phone: string | number;
  impersonateOn: TImpersonatedOnUI | null;
};

export type TUserPropertyManagerUI = {
  id: string;
  landlordId: string;
  name: string;
  username: string;
  email: string;
  phone: string | null;
  profilePicture: string | null;
  position: string | null;
  propertyManagementId: string | null;
  propertyManagementName: string | null;
  propertyManagementLogo: string | null;
  canCreateContract: boolean;
  type: EUserRolesUI;
  hasAccessToCourses: boolean;
  plan: string | null;
};

export type TUserPropertyManagerDB = {
  id: string;
  landlordId: string;
  name: string;
  username: string;
  email: string;
  phone: string | null;
  profilePicture: string | null;
  position: string | null;
  propertyManagementId: string | null;
  propertyManagementName: string | null;
  propertyManagementLogo: string | null;
  canCreateContract: boolean;
  type: EUserRolesDB;
  hasAccessToCourses: boolean;
  plan: string | null;
};
export type TUserDataTypeUI = TUserAdminUI | TUserPropertyManagerUI;
export type TUsersDataByTypeUI = {
  [key in EUserRolesUI]?: TUserDataTypeUI | null;
};

export type TUserTypeDataUI = {
  id: string;
  name: string;
  photo: string;
  type: EUserRolesUI;
};

export type TUserTypeDataDB = {
  id: string;
  name: string;
  photo: string;
  type: EUserRolesDB;
};
// export type TUserDataByTypeUI = TUserAdminUI | TUserPropertyManagerUI;
export type TGetUserDataUI = {
  userType: EUserRolesUI | null;
  impersonateOn: boolean | null;
  users: {
    [EUserRolesUI.ADMIN]: TUserAdminUI | null;
    [EUserRolesUI.PROPERTY_MANAGER]: TUserPropertyManagerUI | null;
    [EUserRolesUI.LANDLORD]: TUserPropertyManagerUI | null;
  } | null;
};

export type TNoteDB = {
  landlordId: string;
  text?: string;
  noteId?: string;
};

export type TNoteUI = {
  landlordId: string;
  text?: string;
  noteId?: string;
};

export type THubspotIntegrationDB = {
  propertyManagerId: string;
  needToPushToHubSpot: boolean;
};
export type THubspotIntegrationUI = {
  propertyManagerId: string;
  needToPushToHubSpot: TYesNoOptionType;
};

export type TGetNotSyncedHubspotCountDB = {
  moveInCount: number;
  pendingApprovedCount: number;
  animalWithVerifiedPendingConversionCount: number;
};
export type TGetNotSyncedHubspotCountUI = {
  moveInCount: number;
  pendingApprovedCount: number;
  animalWithVerifiedPendingConversionCount: number;
};
