import { TSelectOptionTypeUi, TSelectProps } from 'antd/components/Select';
import { Moment } from 'moment';
import { TOptionType, TOptionTypeUi } from './general.types';
import { TPropertiesTypeUI } from './property.types';
import { TPolicyOptionTypeUI } from './policy.types';
import { TYesNoOptionType } from './generalTypes';

export type TResidentsPetChargesUploadDataUI = {
  propertyId: string;
  skipMovedOutTenantsAndAnimals?: boolean;
  importType: TOptionTypeUi;
  tenantsFile: File[];
  petChargesFile: File[];
  policy: TOptionTypeUi;
  shouldConsiderMoveOut: string;
  shouldConsiderMissingProperties?: string;
};

export type TIntegrationDataUI = {
  skipMovedOutTenantsAndAnimals?: boolean;
  type: TOptionTypeUi;
  policy: TOptionTypeUi;
  shouldConsiderMoveOut: string;
  shouldConsiderMissingProperties?: string;
  csvFile: File[];
};

type TPreviewResidencyDataProps = {
  numberOfAnimals: number;
  numberOfResidencies: number;
};

type TPreviewPropertyDetailProps = {
  numberOfProperties: number;
  numberOfResidencies: number;
  numberOfApplicants: number;
};

export type TIntegrationResultProps = {
  numberOfNewApplicantAnimals: number;
  foundMovedOutAnimalsFile: string | null;
  numberOfApplicantsToMove: number;
  skippedNotFoundPropertyApplications: TPreviewPropertyDetailProps;
  numberOfFoundMovedOutAnimals: number;
  numberOfFoundMovedOutTenants: number;
  applicantAnimalsFile: string | null;
  applicants: TPreviewResidencyDataProps;
  applicantsFile: string | null;
  foundMovedOutTenantsFile: string | null;
  moveInResidencies: TPreviewResidencyDataProps;
  moveOutResidencies: TPreviewResidencyDataProps;
  numberOfMatchedProperties: number;
  numberOfNewAnimals: number;
  numberOfResidencesUnableToParse: number;
  skippedNotFoundPropertyResidences: TPreviewPropertyDetailProps;
  terminatingMissingPropertyResidences: TPreviewPropertyDetailProps;
  numberOfAnimalsSkippedAsDuplicate: number;
  skippedResidenciesCountBecauseOfTenantDuplication: number;
  numberOfResidenciesToMove: number;
  moveInResidenciesFile: string | null;
  moveInAnimalsFile: string | null;
  moveOutFile: string | null;
  moveResidenciesFile: string | null;
  numberOfPropertiesToOnboard: number;
  numberOfResidenciesToTagAsKnown: number;
  numberOfAnimalsToTagAsKnown: number;
};

export type TIntegrationResultPropsUI = {
  numberOfNewApplicantAnimals: number;
  foundMovedOutAnimalsFile: Blob | null;
  numberOfFoundMovedOutAnimals: number;
  numberOfFoundMovedOutTenants: number;
  foundMovedOutTenantsFile: Blob | null;
  applicantAnimalsFile: Blob | null;
  applicantsResidencies: number;
  applicantsAnimals: number;
  applicantsFile: Blob | null;
  movedInResidencies: number;
  movedInTotalAnimals: number;
  movedInAnimals: number;
  numberOfNewAnimals: number;
  movedOutTotalResidencies: number;
  movedOutResidencies: number;
  movedOutAnimals: number;
  movedOutResidenciesFromMissingProperty: number;
  numberOfMissingProperties: number;
  numberOfMatchedProperties: number;
  numberOfSkippedResidencies: number;
  numberOfSkippedProperties: number;
  numberOfSkippedApplicants: number;
  numberOfSkippedApplicantsProperties: number;
  numberOfAnimalsSkippedAsDuplicate: number;
  skippedResidenciesCountBecauseOfTenantDuplication: number;
  numberOfResidenciesToMove: number;
  numberOfApplicantsToMove: number;
  moveInResidenciesFile: Blob | null;
  moveInAnimalsFile: Blob | null;
  moveOutFile: Blob | null;
  moveResidenciesFile: Blob | null;
  numberOfPropertiesToOnboard: number;
  numberOfResidenciesToTagAsKnown: number;
  numberOfAnimalsToTagAsKnown: number;
};

export type TIntegrationExecutionResultProps = {
  matchedPropertiesMoveInResidencies: number;
  matchedPropertiesMoveOutResidencies: number;
  terminatingMissingPropertyResidences: number;
};

export type TIntegrationType = 'APFOLIO' | 'REAL_PAGE' | 'YARDI' | null;

export type TSycnScheduleCustomDB = {
  format: string;
  time: string;
};
export type TSycnScheduleCustomUI = {
  format: string;
  time: string;
};

export type TNotChargedPetsReportScheduleSettingsUI = {
  unit: TOptionTypeUi;
  day: TOptionTypeUi;
  hour: TOptionTypeUi;
  positionIds: TOptionTypeUi[];
};

export type TNotChargedPetsReportScheduleSettingsDB = {
  periodicity: {
    unit: ESycnPeriodicityDB;
    day: number;
    hour: number;
  };
  positionIds: string[];
};

export type TNotChargedPetsReportScheduleGetUI = {
  unit: TOptionTypeUi;
  day: number;
  hour: number;
  positionIds: TOptionTypeUi[];
};
export type TNotChargedPetsReportScheduleGetDB = {
  periodicity: {
    unit: ESycnPeriodicityDB;
    day: number;
    hour: number;
  };
  position: TOptionType[];
};

export type TSycnScheduleDB = {
  residentSyncPeriodicity: TSyncPeriodicityDB;
  newApplicantsSyncPeriodicity: TSyncPeriodicityDB;
  applicantsUpdateSyncPeriodicity: TSyncPeriodicityDB;
  notChargedPetsReportScheduleSettings?: TNotChargedPetsReportScheduleSettingsDB[];
};
export type TSycnScheduleUI = {
  residents: TSelectOptionTypeUi;
  newApplicants: TSelectOptionTypeUi;
  applicantsUpdate: TSelectOptionTypeUi;
  residentsUnit?: TSelectOptionTypeUi;
  residentsQuantity?: TSelectOptionTypeUi;
  newApplicantsUnit?: TSelectOptionTypeUi;
  newApplicantsQuantity?: TSelectOptionTypeUi;
  applicantsUpdateUnit?: TSelectOptionTypeUi;
  applicantsUpdateQuantity?: TSelectOptionTypeUi;
  petRentChargeCodes?: string[];
  petFeeChargeCodes?: string[];
  notChargedPetsReportScheduleSettings?: TNotChargedPetsReportScheduleSettingsUI[];
};

export type TYardiChargeCodesDB = {
  petRentChargeCodes?: string[];
  petFeeChargeCodes?: string[];
};
export type TYardiChargeCodesUI = {
  petRentChargeCodes?: string[];
  petFeeChargeCodes?: string[];
};
export type TSyncPeriodicityDB = {
  unit: ESycnPeriodicityDB;
  quantity: number;
};
export type TSyncPeriodicityUI = {
  unit: ESycnPeriodicityUI;
  quantity: number;
  previewText?: string;
};

export type TConnectYardiIntegrationDB = {
  username: string;
  password: string;
  database: string;
  baseUrl: string;
  isTestMode: boolean;
  petRentChargeCodes?: string[];
  petFeeChargeCodes?: string[];
};
export type TConnectYardiIntegrationUI = {
  username: string;
  password: string;
  database: string;
  baseUrl: string;
  petRentChargeCodes?: string[];
  petFeeChargeCodes?: string[];
  isTestMode?: boolean;
};

export type TYardiVersionsDB = {
  coreInterfaceVersion: string;
  pluginVersion: string;
  voyagerVersion: string;
};
export type TYardiVersionsUI = {
  coreInterfaceVersion: string;
  pluginVersion: string;
  voyagerVersion: string;
};

export type TPetChargeSyncEnableValidateDB = {
  chargeCodeInfo: {
    petFeeChargeCodes: string[];
    petRentChargeCodes: string[];
  };
  policyInfo: {
    petFeeChargeAmount: number;
    petRentChargeAmount: boolean;
  };
};

export type TPetChargeSyncEnableValidateUI = {
  canEnable: boolean;
  chargeCodesErrorStr?: string;
  isPolicyError: boolean;
  chargeCodeInfo: {
    petFeeChargeCodes: string[];
    petRentChargeCodes: string[];
  };
  policyInfo: {
    petFeeChargeAmount: number;
    petRentChargeAmount: boolean;
  };
};

export type TYardiIntegrationDB = {
  applicantsUpdateSyncPeriodicity: TSyncPeriodicityDB | null;
  canConnect: boolean;
  database: string | null;
  id: string | null;
  newApplicantsSyncPeriodicity: TSyncPeriodicityDB | null;
  password: string | null;
  residentSyncPeriodicity: TSyncPeriodicityDB | null;
  username: string | null;
  baseUrl: string | null;
  ilgGuestCardVersion: TYardiVersionsDB;
  residentDataVersion: TYardiVersionsDB;
  petFeeChargeCodes: string[];
  petRentChargeCodes: string[];
  isTestMode: boolean;
  notChargedPetsReportSchedules: TNotChargedPetsReportScheduleGetDB[];
};

export type TYardiIntegrationUI = {
  applicantsUpdateSyncPeriodicity: TSyncPeriodicityUI | null;
  canConnect: boolean;
  database: string;
  id: string | null;
  newApplicantsSyncPeriodicity: TSyncPeriodicityUI | null;
  password: string;
  residentSyncPeriodicity: TSyncPeriodicityUI | null;
  username: string;
  baseUrl: string;
  ilgGuestCardVersion: TYardiVersionsUI | null;
  residentDataVersion: TYardiVersionsUI | null;
  petFeeChargeCodes: string[] | null;
  petRentChargeCodes: string[] | null;
  isTestMode: boolean;
  notChargedPetsReportScheduleSettings: TNotChargedPetsReportScheduleGetUI[];
};

export type TYardiPropertyDB = {
  id: string;
  notChargedPetsSyncEnabled: boolean;
  notChargedPetsSyncLastSyncDateTime: null | string;
  name: string;
  propertyMatched: false;
  state: string;
  unitsCount: number;
  applicantsSyncEnabled: boolean;
  residentsSyncEnabled: boolean;
  followUpTenantsWithAnimalStatusNo: boolean;
  city: string;
  policyId: string;
  address1: string;
  zip: string;
  existingApplicantsLastSyncTime: string;
  newApplicantsLastSyncTime: string;
  residentsLastSyncTime: string;
  canPlanSync: boolean;
  matchedProperty: string | null;
  internalPolicyId: string | null;
};
export type TYardiPropertyUI = {
  internalPolicyId: string | null;
  canPlanSync: boolean;
  notChargedPetsSyncEnabled: boolean;
  notChargedPetsSyncLastSyncDateTime?: string;
  id: string;
  name: string;
  propertyMatched: false;
  state: string;
  unitsCount: number;
  isSyncEnabled: boolean;
  applicantsSyncEnabled: boolean;
  residentsSyncEnabled: boolean;
  followUpTenantsWithAnimalStatusNo: string | null;
  city: string;
  policyId: string;
  address1: string;
  zip: string;
  existingApplicantsLastSyncTime?: string;
  newApplicantsLastSyncTime?: string;
  residentsLastSyncTime?: string;
  matchedProperty: string | null;
  notChargedPetsReportScheduleSettings?: TNotChargedPetsReportScheduleSettingsUI[];
};

export enum ESycnPeriodicityDB {
  DAILY = 'DAYS',
  WEEKLY = 'WEEKS',
  MONTHLY = 'MONTHS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
}

export enum ESycnPeriodicityUI {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  MINUTES = 'Minutes',
  HOURS = 'Hours',
}

export type TPropertyForMatchDB = {
  hasAssignedPolicy: boolean;
  id: string;
  name: string;
  policyId: string | null;
  internalPolicyId: string | null;
};
export type TPropertyForMatchUI = {
  defaultPolicy: boolean;
  label: string;
  value: string;
  policyId: string | null;
  internalPolicyId: string | null;
};

export type TYardiPropertyMatchDB = {
  propertyId: string;
  linkedPropertyId: string;
  applicantsEnabled: boolean;
  residentsEnabled: boolean;
  notChargedPetsEnabled?: boolean;
  followUpTenantsWithAnimalStatusNo?: boolean;
};
export type TYardiPropertyMatchUI = {
  propertyId: string;
  residentsEnabled?: boolean;
  applicantsEnabled?: boolean;
  notChargedPetsEnabled?: boolean;
  matchedProperty: TPropertyForMatchUI;
  policy: TPolicyOptionTypeUI;
  followUpTenantsWithAnimalStatusNo?: string;
  newPropertyId?: string;
};

export type TPropertySyncStateDB = {
  propertyId: string;
  notChargedPetsEnabled?: boolean;
  applicantsEnabled?: boolean;
  residentsEnabled: boolean;
  followUpTenantsWithAnimalStatusNo?: boolean | null;
};
export type TPropertySyncStateUI = {
  propertyId: string;
  notChargedPetsEnabled?: boolean;
  applicantsEnabled: boolean;
  residentsEnabled: boolean;
  followUpTenantsWithAnimalStatusNo?: TYesNoOptionType;
};

export type TResmanConnectDB = {
  resManId: string;
  syncPeriodicity?: {
    unit: string;
    quantity: number;
  };
};
export type TResmanConnectUI = {
  resManId: string;
  syncPeriodicity?: TOptionTypeUi;
  settingsId?: string | null;
};

export type TResmanIntegrationDB = {
  accountId: string | null;
  canConnect: boolean | null;
  id: string | null;
  syncPeriodicity: {
    unit: string;
    quantity: number;
  };
};
export type TResmanIntegrationUI = {
  accountId?: string;
  canConnect: boolean;
  id?: string;
  syncPeriodicity?: TSelectOptionTypeUi;
};

export type TResmanPropertiesDB = {
  id: string;
  name: string;
  address1: string;
  city: string;
  unitCount: number;
  type: string;
  status: string;
  state: string;
  isStateSupported: boolean;
  lastSyncDate: string | null;
  policy: string | null;
  propertyMatched: boolean;
  enabled: boolean;
  matchedProperty: string | null;
  canPlanSync: boolean;
};
export type TResmanPropertiesUI = {
  id: string;
  canPlanSync: boolean;
  name: string;
  address1: string;
  city: string;
  unitsCount: number;
  type: string;
  status: string;
  state: string;
  isStateSupported: boolean;
  residentsLastSyncTime?: string;
  policy: string | null;
  propertyMatched: boolean;
  isSyncEnabled: boolean;
  residentsSyncEnabled: boolean;
  matchedProperty: string | null;
};

export type TResmanPropertyMatchDB = {
  propertyId: string;
  linkedPropertyId: string;
  residentsEnabled: boolean;
};
export type TResmanPropertyMatchUI = {
  propertyId: string;
  linkedPropertyId: string;
  residentsEnabled: boolean;
};

export type TResmanPropertySyncStateDB = {
  propertyId: string;
  residentsEnabled: string;
};

export type TResmanPropertySyncStateUI = {
  propertyId: string;
  residentsEnabled: string;
};

export enum EIntegrationType {
  YARDI = 'Yardi',
  RESMAN = 'Resman',
  REAL_PAGE = 'Real Page',
}
export enum EIntegrationTypeDB {
  YARDI = 'YARDI',
  RESMAN = 'RESMAN',
  REAL_PAGE = 'REAL_PAGE',
}

export type TIntegrationDataExportUI = {
  propertyId: string;
  propertyName: string;
  propertyManagementId: string;
  integrationType: 'Yardi' | 'Resman';
};

export type TIntegrationDataExportDB = {
  propertyManagementId: string;
  yardiPropertyId?: string;
  resManPropertyId?: string;
};

export type TRealPageIntegrationSettingsDB = {
  id?: string;
  canConnect: boolean;
  isConnected: boolean;
  lastUploadDate?: string;
};
export type TRealPageIntegrationSettingsUI = {
  id?: string;
  canConnect: boolean;
  isConnected: boolean;
  lastUploadDate?: string;
};

export type TTPConnectDB = {
  type: EIntegrationTypeDB;
  settings?: null; // TODO update this to integration setting when endpoinds are changed
};
export type TTPConnectUI = {
  type: EIntegrationTypeDB;
  settings?: null;
};

export type TTPDisconnectDB = {
  thirdPartySettingsId: string;
  type: EIntegrationTypeDB;
};
export type TTPDisconnectUI = {
  thirdPartySettingsId: string;
  type: EIntegrationTypeDB;
};

export type TPUploadSyncUI = {
  type: EIntegrationTypeDB;
  zipFile: File[];
};

export type TZipUploadUI = {
  zipFile: File[];
};

export type TTPPropertiesDB = {
  address1?: string;
  city?: string;
  id: string;
  integrationType: string;
  lastSuccessfulSyncDate?: string;
  linkedProperty?: { propertyId: string; name: string };
  matchedProperty: boolean;
  name: string;
  state?: string;
  syncEnabled: boolean;
  syncStatus?: string;
  unitsCount?: number;
  zip?: string;
  canEnableSync: boolean;
  canStartSync: boolean;
};

export type TTPPropertiesUI = {
  address1?: string;
  city?: string;
  id: string;
  integrationType: string;
  lastSuccessfulSyncDate?: string;
  linkedProperty?: { propertyId: string; name: string };
  matchedProperty: boolean;
  name: string;
  state?: string;
  syncEnabled: boolean;
  syncStatus?: string;
  unitsCount?: number;
  zip?: string;
};

export type TTPSyncStateProps = {
  thirdPartyPropertyId: string;
  integrationType: string;
};

export type TTPPropertyLink = {
  integrationType: string;
  thirdPartyPropertyId: string;
  propertyId: string;
};
export type TTPPropertyToLinkDB = {
  id: string;
  name: string;
  hasAssignedPolicy: boolean;
};

export type TTPPropertyToLinkUI = {
  value: string;
  label: string;
  hasAssignedPolicy: boolean;
};
