import { Moment } from 'moment';
import { TSelectedPacket } from 'antd/pages/private/Animals/AnimalDetails/AnimalDocumentationBlock/DocumentsTabContent/DocumentsTabContent.types';
import { TSADocPacketGet, TSADocPacketGetUI } from './animalTraining.types';
import { TDocPacketGet, TDocPacketGetUI } from './document.types';
import {
  EApplicationStatus,
  EApplicationStatusUi,
  EGender,
  EGenderUi,
  TExpirationDateType,
  TFileDataType,
  TOptionType,
  TOptionTypePredefinedDB,
  TOptionTypeUi,
  TPolicyUI,
  TPropertyUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TStatusInfoWithSourceUI,
  TUnitUI,
} from './general.types';
import {
  EVerificationStatus,
  EVerificationStatusUi,
  TYesNoOptionType,
} from './generalTypes';
import {
  EAnimalResidencyStatusDB,
  EAnimalResidencyStatusUI,
  EPooCompaniesDB,
  EResidencyStatusDB,
  EResidencyStatusUI,
  EResidentTagsDB,
  EResidentTagsUI,
  TCompanyDB,
  TCompanyUi,
  TResidenciesOptionsUI,
} from './residence.types';
import {
  TAnimalVetRecord,
  TAnimalVetRecordUi,
  TVetRecordTypeUI,
} from './vetRecord.types';
import {
  TFollowUpItemDB,
  TFollowUpItemUI,
  TStatusChangeHistoryDB,
  TStatusChangeHistoryUI,
} from './application.types';
import { EConversionReasonDB, EConversionReasonUI } from '../datas/genericData';

export enum EAnimalTypes {
  ESA = 'ESA',
  PET = 'PET',
  SA = 'SA',
}

export enum EAnimalTypesUI {
  ESA = 'ESA',
  PET = 'PET',
  SA = 'SA',
}

export type TPropertyDB = {
  name: string;
  propertyId: string;
};

export enum EAnimalStatusDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  MOVED_OUT = 'MOVED_OUT',
  ARCHIVED = 'ARCHIVED',
  RETRACTED = 'RETRACTED',
  MOVED_IN = 'MOVED_IN',
  CONDITIONALLY_APPROVED = 'CONDITIONALLY_APPROVED',
  CONDITIONALLY_MOVED_IN = 'CONDITIONALLY_MOVED_IN',
}

export enum EAnimalStatusUI {
  PENDING = 'Pending',
  APPROVED = 'Future Animal',
  NOT_APPROVED = 'Not Approved',
  MOVED_OUT = 'Moved Out',
  ARCHIVED = 'Archived',
  RETRACTED = 'Retracted',
  MOVED_IN = 'Moved In',
  CONDITIONALLY_APPROVED = 'Conditionally Approved',
  CONDITIONALLY_MOVED_IN = 'Conditionally Moved In',
}

export enum EPreventedAnimalStatusDB {
  NONE = 'NONE',
  NOT_APPROVED = 'NOT_APPROVED',
  RETRACTED = 'RETRACTED',
}

export enum EPreventedAnimalStatusUI {
  NONE = 'None',
  NOT_APPROVED = 'Not Approved',
  RETRACTED = 'Retracted',
}

export enum EAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
}

export enum EAuthorizationStatusUi {
  AUTHORIZED = 'Authorized',
}

export enum EResidenceStatusDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  MOVED_OUT = 'MOVED_OUT',
  ARCHIVED = 'ARCHIVED',
  RETRACTED = 'RETRACTED',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
}

export enum EResidenceStatusUI {
  PENDING = 'Pending',
  APPROVED = 'Aproved',
  NOT_APPROVED = 'Not Approved',
  MOVED_OUT = 'Moved out',
  ARCHIVED = 'Archived',
  RETRACTED = 'Retracted',
  FORMER_RESIDENT = 'Former Resident',
}

export enum EPictureOverviewStatusUI {
  MISSING = 'MISSING',
  SPECIFIED = 'SPECIFIED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum EPictureOverviewStatusDB {
  MISSING = 'MISSING',
  SPECIFIED = 'SPECIFIED',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum EPolicyOverviewStatusDB {
  MISSING = 'MISSING',
}

export enum EPolicyOverviewStatusUI {
  MISSING = 'MISSING',
}

export enum EVetRecordOverviewStatusUI {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum EVetRecordOverviewStatusDB {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export enum EDocsOverviewStatusUI {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
}

export enum EDocsOverviewStatusDB {
  HAS_VERIFIED = 'HAS_VERIFIED',
  HAS_UNVERIFIED = 'HAS_UNVERIFIED',
  MISSING = 'MISSING',
}

export type TAnimalResidenceFilterDB = {
  propertyName: string;
  residenceIds: string[];
  residentName: string;
  uniNumber: string;
};

export type TAnimalResidenceFilterUI = {
  id: string;
};
export type TResidentProfileDB = {
  name: string;
  residentId: string;
};

export type TResidentProfileUI = {
  name: string;
  residentId: string;
};

export type TAnimalUnitDB = {
  number: string;
  property: TPropertyDB;
};

export type TAnimalUnitUI = {
  number: string;
  property: TPropertyDataUi;
};

export type TAnimalResidenceDB = {
  dnaEnabled: boolean | null;
  leaseToDate: string | null;
  moveInDate: string | null;
  policyId: string | null;
  residenceId: string;
  residentProfile: TResidentProfileDB;
  status: EResidenceStatusDB | null;
  unit: TAnimalUnitDB;
  tenantResidencyStatus: EResidencyStatusDB;
  tenantResidencyMoveOutDate: string | null;
  tenantResidencyMoveInDate: string | null;
};

export type TAnimalResidenceUI = {
  dnaEnabled: boolean | null;
  leaseToDate: string | null;
  moveInDate: string | null;
  policyId: string | null;
  residenceId: string;
  residentProfile: TResidentProfileUI;
  status: EResidenceStatusUI | null;
  unit: TAnimalUnitUI;
  tenantResidencyStatus: EResidencyStatusUI;
  tenantResidencyMoveOutDate: string | null;
  tenantResidencyMoveInDate: string | null;
};

export type TInfoDNA = {
  isDnaRequired: boolean;
  isDnaCollected: boolean;
  dnaNumber: string | null;
  dnaCompany: TCompanyDB | null;
};

export type TInfoDNAUi = {
  isDnaRequired: boolean;
  isDnaCollected: boolean;
  dnaNumber: string | null;
  dnaCompany: TCompanyUi | null;
};

export type TDnaInfoDB = {
  company: EPooCompaniesDB;
  companyCustomName: string | null;
  dnaNumber: string | null;
  isCollected: false;
};

export type TDnaInfoUI = {
  company: { label: string | null; value: EPooCompaniesDB };
  companyCustomName?: string;
  dnaNumber: string;
  isCollected: false;
};

export type TAnimalWeightDB = {
  unit: string;
  value: number;
};

export type TAnimalWeightUI = {
  unit: string;
  value: number;
};

export enum ENotBeingChargedReasonDB {
  PET_FEE = 'PET_FEE',
  PET_RENT = 'PET_RENT',
}

export enum ENotBeingChargedReasonUI {
  PET_FEE = 'No pet fee charged',
  PET_RENT = 'No pet rent charged',
}

export enum EConversionStatusUI {
  NONE = 'None',
  PENDING_SA = 'Pending SA',
  PENDING_ESA = 'Pending ESA',
}

export enum EConversionStatusDB {
  NONE = 'NONE',
  PENDING_SA = 'PENDING_SA',
  PENDING_ESA = 'PENDING_ESA',
}

export type TAnimalDB = {
  animalConversionStatus: EConversionStatusDB;
  id: string;
  name: string;
  chargingStatusInfo: TStatusInfoWithSourceDB | null;
  animalVerificationStatus: EVerificationStatus;
  birthday: string;
  breed: TOptionType | null;
  species: TOptionType | null;
  primaryColor: TOptionTypePredefinedDB | null;
  deleted: boolean;
  dnaFeatureEnabled: boolean;
  dnaInfo: TDnaInfoDB;
  propertyName: string;
  profilePicture: string;
  isPropertyCustom: boolean;
  type: EAnimalTypes;
  unitNumber: string;
  status: EAnimalStatusDB;
  weight: TAnimalWeightDB | null;
  residence: TAnimalResidenceDB | null;
  statusChangeInfo: TStatusChangeInfoDB | null;
  existsNonApprovedAnimalApplication: boolean;
  submissionDate: string;
  chargingStatus: EAnimalCharginStatusDB;
  notBeingChargedReason: ENotBeingChargedReasonDB | null;
  tags: EAnimalTagsDB[];
  discoveredTagReason: string | null;
  secondaryColor1: TOptionTypePredefinedDB | null;
  secondaryColor2: TOptionTypePredefinedDB | null;
  yardiId: string | null;
  unitPaidPetRent: number | null;
  unitPaidPetFee: number | null;
  unitPetCount: number | null;
  tenantLeaseStartDate: string;
  tenantLeaseEndDate: string;
  isToPetConverted: boolean;
  animalPresenceStatus: string;
  isEmployeeAnimal: boolean;
  animalMoveOutDate?: string;
};

export type TAnimalUI = {
  animalConversionStatus: EConversionStatusUI;
  id: string;
  name: string;
  chargingStatusInfo: TStatusInfoWithSourceUI | null;
  animalVerificationStatus: EVerificationStatusUi;
  birthday: string;
  breed: TOptionTypeUi | null;
  species: TOptionTypeUi | null;
  primaryColor: TOptionTypeUi | null;
  deleted: boolean;
  dnaFeatureEnabled: boolean;
  dnaInfo: TDnaInfoUI | null;
  propertyName: string;
  profilePicture: string;
  isCustom: boolean;
  type: EAnimalTypesUI;
  unitNumber: string;
  status: EAnimalStatusUI;
  weight: TAnimalWeightUI | null;
  residence: TAnimalResidenceUI | null;
  firstLetters: string;
  statusChangeInfo: TStatusChangeInfoUI | null;
  existsNonApprovedAnimalApplication: boolean;
  isApproved: string;
  submitionDate: string | null;
  chargingStatus: EAnimalCharginStatusUI;
  notBeingChargedReason: ENotBeingChargedReasonUI | null;
  tags: EAnimalTagsUI[];
  discoveredTagReason: string | null;
  secondaryColor1: TOptionTypeUi | null;
  secondaryColor2: TOptionTypeUi | null;
  yardiId: string | null;
  unitPaidPetRent: string;
  unitPaidPetFee: string;
  unitPetCount: number | null;
  isToPetConverted: boolean;
  animalPresenceStatus: string;
  isEmployeeAnimal: string;
  animalMoveOutDate?: string;
};

export type TAddedPetsDB = {
  isPropertyCustom: boolean;
  id: string;
  animalConversionStatus: EConversionStatusDB;
  name: string;
  profilePicture: string;
  propertyName: string;
  unitNumber: string;
  birthday: string;
  breed: TOptionType | null;
  species: TOptionType | null;
  weight: TAnimalWeightDB | null;
  conversionDate: string;
  primaryColor: TOptionTypePredefinedDB | null;
  residence: TAnimalResidenceDB | null;
  status: EAnimalStatusDB;
  statusChangeInfo: TStatusChangeInfoDB | null;
  notBeingChargedReason: ENotBeingChargedReasonDB | null;
  chargingStatus: EAnimalCharginStatusDB;
  tags: EAnimalTagsDB[];
  isToPetConverted: boolean;
  animalVerificationStatus: EVerificationStatus;
  discoveredTagReason: string | null;
  chargingStatusInfo: TStatusInfoWithSourceDB | null;
};

export type TAddedPetsUI = {
  isCustom: boolean;
  id: string;
  name: string;
  animalConversionStatus: EConversionStatusUI;
  chargingStatusInfo?: TStatusInfoWithSourceUI | null;
  profilePicture: string;
  propertyName: string;
  unitNumber: string;
  birthday: string;
  breed: TOptionTypeUi | null;
  species: TOptionTypeUi | null;
  weight: TAnimalWeightUI | null;
  conversionDate: string;
  primaryColor: TOptionTypeUi | null;
  residence: TAnimalResidenceUI | null;
  status: EAnimalStatusUI;
  statusChangeInfo: TStatusChangeInfoUI | null;
  firstLetters: string;
  notBeingChargedReason: ENotBeingChargedReasonUI | null;
  chargingStatus: EAnimalCharginStatusUI;
  tags: EAnimalTagsUI[];
  isToPetConverted: boolean;
  animalVerificationStatus: EVerificationStatusUi;
  discoveredTagReason: string | null;
};

export type TPreventedAnimalDB = {
  isPropertyCustom: boolean;
  animalId: string;
  animalBreed: TOptionType | null;
  animalCreationTime: string;
  animalName: string;
  animalPrimaryColor: TOptionTypePredefinedDB | null;
  animalProfilePicture: string;
  animalSpecie: TOptionType | null;
  animalType: EAnimalTypes;
  animalVerificationStatus: EVerificationStatus;
  applicationRejectReason: string | null;
  isAnimalToPetConverted: string;
  propertyName: string;
  preventionType: EPreventedAnimalStatusDB;
  preventionReason: string;
  tenantName: string;
  unitNumber: string;
  id: string;
  ownerStatus: EResidencyStatusDB;
};

export type TPreventedAnimalUI = {
  propertyCustom: boolean;
  animalId: string;
  animalBreed: TOptionTypeUi | null;
  animalCreationTime: string;
  animalName: string;
  animalPrimaryColor: TOptionTypeUi | null;
  animalProfilePicture: string;
  animalSpecie: TOptionTypeUi | null;
  animalType: EAnimalTypesUI;
  animalVerificationStatus: EVerificationStatusUi;
  applicationRejectReason?: string;
  isAnimalToPetConverted: string;
  propertyName: string;
  preventionType: EPreventedAnimalStatusUI;
  preventionReason: string;
  tenantName: string;
  unitNumber: string;
  firstLetters: string;
  id: string;
  ownerStatus: EResidencyStatusUI;
};

export type TAnimalTerminateDB = {
  residenceId: string;
  animalResidencyId: string;
  reason: string;
};

export type TAnimalTerminateUI = {
  residenceId: string;
  animalResidencyId: string;
  reason: string;
};

export type TAggressiveBehaviorDB = {
  acted: boolean;
  incidentDetails?: string;
};

export type TAggressiveBehaviorUI = {
  acted: string;
  incidentDetails?: string;
};

export type TAnimalInfoPropDB = {
  value: string;
  predefinedId?: string;
  predefined?: boolean;
};
export type TAnimalInfoPropUI = {
  value: string;
  predefinedId?: string;
  predefined?: boolean;
};

export type TAnimalBasicInfo = {
  birthDate: string;
  breed: TAnimalInfoPropDB | null;
  gender: EGender;
  id: string;
  isHouseTrained: boolean | null;
  isReproductive: boolean | null;
  name: string;
  photoGallery: string[];
  primaryColor: TAnimalInfoPropDB | null;
  profilePicture: string | null;
  secondaryColor1: TAnimalInfoPropDB | null;
  secondaryColor2: TAnimalInfoPropDB | null;
  species: TAnimalInfoPropDB | null;
  verificationStatus: EVerificationStatus;
  type: EAnimalTypes;
  weight: TAnimalWeightDB | null;
  aggressiveBehavior: TAggressiveBehaviorDB | null;
  isRequiredBecauseOfDisability?: boolean;
  trainedFor?: string;
  microchip: string | null;
  editAllowed: boolean;
};

export type TAnimalBasicInfoUi = {
  birthDate: string | null;
  breed: TAnimalInfoPropUI | null;
  gender: EGenderUi;
  id: string;
  isHouseTrained: boolean | null;
  reproduction: boolean | null;
  name: string;
  photoGallery: string[];
  primaryColor: TAnimalInfoPropUI | null;
  profilePicture: string | null;
  secondaryColor1: TAnimalInfoPropUI | null;
  secondaryColor2: TAnimalInfoPropUI | null;
  species: TAnimalInfoPropUI | null;
  verificationStatus: EVerificationStatusUi;
  type: EAnimalTypesUI;
  weight: TAnimalWeightUI | null;
  aggressiveBehavior: TAggressiveBehaviorUI | null;
  isRequiredBecauseOfDisability?: boolean;
  trainedFor?: string;
  microchip: string | null;
  isEditAllowed: boolean;
};

export type TProperty = {
  propertyId: string;
  name: string;
};

export type TPropertyDataUi = {
  propertyId: string;
  name: string;
};

export type TUnit = {
  number: string;
  unitId: string;
  property: TProperty;
};

export type TUnitUi = {
  number: string;
  unitId: string;
  property: TPropertyDataUi;
};

export type TAnimalOwnerInfoUI = {
  id: string;
  email: string | null;
  moveInDate: string | null;
  leaseToDate: string | null;
  name: string;
  phoneNumber: string | null;
  policy: TPolicyUI;
  profilePicture: string | null;
  property: TPropertyUI;
  residencyStatus: EResidencyStatusUI;
  unit: TUnitUI;
  residentId: string;
  tags: EResidentTagsUI[];
  isPropertyCustom: boolean;
};

export type TAnimalOwnerInfoDB = {
  id: string;
  email: string | null;
  moveInDate: string;
  leaseToDate: string | null;
  name: string;
  phoneNumber: string | null;
  policyId: string;
  policyName: string;
  policyState: string;
  profilePicture: string | null;
  propertyId: string;
  propertyName: string;
  isPropertyCustom: boolean;
  status: EResidencyStatusDB;
  unitId: string;
  unitName: string;
  residentId: string;
  tags: EResidentTagsDB[];
  dateSigned: string | null;
  isSigned: boolean;
  signedBy: string | null;
};
export type TCurriculumDB = {
  checkpoint?: number;
  isPassed: boolean;
  isRequired: boolean;
  totalCount?: number;
};
export type TCurriculumUI = {
  checkpoint?: number;
  isPassed: boolean;
  isRequired: boolean;
  totalCount?: number;
};

export type TDataOverviewUI = {
  curriculum?: TCurriculumUI;
  profilePicture: EPictureOverviewStatusUI;
  vetRecord: EVetRecordOverviewStatusUI;
  hpDocs?: EDocsOverviewStatusUI;
  trainerDocs?: EDocsOverviewStatusUI;
  isPolicySigned?: EPolicyOverviewStatusUI;
};

export type TDataOverviewDB = {
  curriculum?: TCurriculumDB;
  profilePicture: EPictureOverviewStatusDB;
  vetRecord: EVetRecordOverviewStatusDB;
  hpDocs?: EDocsOverviewStatusDB;
  trainerDocs?: EDocsOverviewStatusDB;
  isPolicySigned: EPolicyOverviewStatusDB;
};

export type TAnimalApplication = {
  applicationStatus: EApplicationStatus;
  id: string;
  leaseStartDate: string;
  leaseToDate: string | null;
  policyId: string;
  propertyManagerName: string;
  propertyName: string;
  unitNumber: string;
};

export type TAnimalApplicationUi = {
  applicationStatus: EApplicationStatusUi;
  id: string;
  leaseStartDate: string;
  leaseToDate: string | null;
  policyId: string;
  propertyName: string;
  propertyManagerName: string;
  unitNumber: string;
};

export enum EAnimalTagsDB {
  'PNC' = 'PNC',
  'KNOWN' = 'KNOWN',
  'DISCOVERD' = 'DISCOVERED',
}

export enum EAnimalTagsUI {
  'PNC' = 'PNC',
  'KNOWN' = 'Known',
  'DISCOVERD' = 'Discovered',
}

export type TAnimalDetail = {
  chargingStatusInfo: TStatusInfoWithSourceDB | null;
  hasObservableDisability: boolean | null;
  animal: TAnimalBasicInfo;
  applications: TAnimalApplication[] | null;
  dnaInfo: TInfoDNA;
  ownerInfo: TAnimalOwnerInfoDB;
  vetRecords: TAnimalVetRecord[] | null;
  hpDocPackets: TDocPacketGet[] | null;
  residencyStatus: EAnimalResidencyStatusDB;
  residencyStatusChangeInfo: TStatusChangeInfoDB | null;
  saDocuments: TSADocPacketGet[] | null;
  dataOverview: TDataOverviewDB;
  isToPetConverted: boolean;
  statusChangeHistory: TStatusChangeHistoryDB[];
  policyContent: string | null;
  animalApplicationId: string | null;
  canRevokeMoveIn: boolean;
  chargingStatus: EAnimalCharginStatusDB;
  notBeingChargedReason: ENotBeingChargedReasonDB | null;
  tags: EAnimalTagsDB[];
  animalMoveInDate: string;
  followUpItems: TFollowUpItemDB[] | null;
  discoveredTagReason: string | null;
  statusUpdateLocked: boolean;
  isScreeningEnabled: boolean;
  toPetConvertedReason: string | null | EConversionReasonDB;
  missingNameReason: string | null;
  missingTypeReason: string | null;
  missingMoveInDateReason: string | null;
};

export type TAnimalDetailUi = {
  chargingStatusInfo: TStatusInfoWithSourceUI | null;
  hasObservableDisability: boolean | null;
  animal: TAnimalBasicInfoUi;
  applications: TAnimalApplicationUi[] | null;
  dnaInfo: TInfoDNAUi;
  ownerInfo: TAnimalOwnerInfoUI;
  vetRecords: TAnimalVetRecordUi[] | null;
  hpDocPackets: TDocPacketGetUI[] | null;
  residencyStatus: EAnimalResidencyStatusUI;
  residencyStatusChangeInfo: TStatusChangeInfoUI | null;
  saDocuments: TSADocPacketGetUI[] | null;
  dataOverview: TDataOverviewUI;
  isToPetConverted: boolean;
  statusChangeHistory: TStatusChangeHistoryUI[];
  policyContent: Blob | null;
  pendingAnimalApplicationId: string | null;
  canRevokeMoveIn: boolean;
  chargingStatus: EAnimalCharginStatusUI;
  notBeingChargedReason: ENotBeingChargedReasonUI | null;
  animalMoveInDate: string;
  tags: EAnimalTagsUI[];
  followUpItems: TFollowUpItemUI[] | null;
  discoveredTagReason: string | null;
  statusUpdateLocked: boolean;
  isScreeningEnabled: boolean;
  toPetConvertedReason: string | null | EConversionReasonUI;
  missingNameReason: string | null;
  missingTypeReason: string | null;
  missingMoveInDateReason: string | null;
};

export type TAnimalStatisticUI = {
  fromDate: string;
  totalCount: number;
};

export type TAnimalStatisticDB = {
  fromDate: string;
  totalCount: number;
};

export type TAnimalsStatisticsUI = {
  addedPets: TAnimalStatisticUI;
  preventedAnimals: TAnimalStatisticUI;
};

export type TAnimalsStatisticsDB = {
  addedPets: TAnimalStatisticDB;
  preventedAnimals: TAnimalStatisticDB;
};

export enum TChangerStatusRolesDB {
  ADMIN = 'ADMIN',
  LANDLORD = 'LANDLORD',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}

export enum TChangerStatusRolesUI {
  ADMIN = 'admin',
  LANDLORD = 'landlord',
  PROPERTY_MANAGER = 'propertyManager',
}

export type TApplicantAnimalDB = {
  id: string;
  type: EAnimalTypes;
  applicationStatus: EApplicationStatus;
  statusChangerRole: TChangerStatusRolesDB | null;
  statusChangerName: string | null;
  rejectReason: string | null;
  moveInDate: string;
  submittedDate: string;
  residentName: string;
  petName: string;
  propertyName: string;
  unitName: string;
  animalVerificationStatus: EVerificationStatus;
  statusChangeInfo: TStatusChangeInfoDB | null;
  animalProfilePicture: string | null;
};

export type TApplicantAnimalUI = {
  id: string;
  type: EAnimalTypesUI;
  applicationStatus: EApplicationStatusUi;
  statusChangerRole: TChangerStatusRolesUI | null;
  statusChangerName: string | null;
  rejectReason: string | null;
  moveInDate: string | null;
  submittedDate: string | null;
  residentName: string;
  petName: string;
  propertyName: string;
  unitName: string;
  animalVerificationStatus: EVerificationStatusUi;
  statusChangeInfo: TStatusChangeInfoUI | null;
  animalProfilePicture: string | null;
};

export type TDuplicateAnimalDB = {
  animalId: string;
  animalName: string;
  animalSpecie: TOptionType | null;
  animalType: EAnimalTypes;
  ownerName: string;
  ownerUsername: string;
  status: EApplicationStatus;
};
export type TDuplicateAnimalUI = {
  animalId: string;
  animalName: string;
  animalType: EAnimalTypesUI;
  ownerName: string;
  ownerUsername: string;
  status: EApplicationStatusUi;
};

export type TCheckDuplicateAnimalDB = {
  animalName: string;
  residenceId: string;
};
export type TCheckDuplicateAnimalUI = {
  animalName: string;
  residenceId: string;
};

export enum EAnimalCharginStatusDB {
  UNSPECIFIED = 'UNSPECIFIED',
  BEING_CHARGED = 'BEING_CHARGED',
  NOT_BEING_CHARGED = 'NOT_BEING_CHARGED',
  SHOULD_NOT = 'SHOULD_NOT',
  GRACE_PERIOD = 'GRACE_PERIOD',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum EAnimalCharginStatusUI {
  UNSPECIFIED = 'Unspecified',
  BEING_CHARGED = 'Being charged',
  NOT_BEING_CHARGED = 'Not being charged',
  SHOULD_NOT = 'Shouldn’t be charged',
  GRACE_PERIOD = 'Grace period',
  NOT_APPLICABLE = 'Not Applicable',
}

export type TGenderUI = { gender: EGenderUi; neutered: TYesNoOptionType };
export type TGender = { type: EGender; isReproductive?: boolean | null };

export type TAnimalSpecieDB = {
  value?: string;
  id?: string | null;
};
export type TAnimalSpecieUI = {
  label: string;
  value: string;
};

export type TAnimalColorsDB = {
  value?: string;
  id: string | null;
};
export type TAnimalColorsUI = {
  label: string;
  value: string;
  __isNew__: boolean;
};

export type TAnimalBreedDB = {
  value?: string;
  id?: string | null;
};
export type TAnimalBreedUI = {
  label: string;
  value: string;
};

export type TCreateAnimalDB = {
  birthDate: string | null;
  breed: TAnimalBreedDB;
  name: string | null;
  primaryColor: TAnimalColorsDB;
  residencyId: string;
  species: TAnimalSpecieDB;
  weight: string | null;
  status: EAnimalResidencyStatusDB;
  moveInDate: string | null;
  animalType: EAnimalTypes;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  profilePicture?: string | null;
  gender: TGender | null;
  houseTrained?: boolean | null;
  requiredBecauseOfDisability?: string;
  trainedFor?: string;
  aggressiveBehavior: {
    acted?: boolean | null;
    incidentDetails?: string;
  };
  microchip?: string;
  needToScreen: boolean;
};

export type TCreateAnimalUI = {
  birthDate: Moment;
  breed: TAnimalBreedUI;
  name: string | null;
  primaryColor: TAnimalColorsUI;
  residencyId: TResidenciesOptionsUI;
  species: TAnimalSpecieUI;
  weight: string;
  status: EAnimalResidencyStatusUI;
  moveInDate?: Moment;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  profilePicture: { url: string } | null;
  gender: EGenderUi | null;
  neutered: TYesNoOptionType;
  houseTrained: TYesNoOptionType;
  animalType: { value: EAnimalTypesUI };
  isAggressive: TYesNoOptionType;
  aggressionInfo?: string;
  microchip?: string;
  requiredBecauseOfDisability?: string;
  trainedFor?: string;
  needToScreen: boolean;
};

export type TCreateAnimalResponseDB = {
  movedIn: boolean;
  animalResidencyId: string | null;
  applicationId: string | null;
};
export type TCreateAnimalResponseUI = {
  isMovedIn: boolean;
  id: string;
};

export type TFollowUpAnimalItemDB = {
  animalId: string;
  description: string;
};

export type TFollowUpAnimalItemUI = {
  animalId: string;
  description: string;
};

export type TStatusInfoWithSourceDB = TStatusChangeInfoDB & {
  exportDate?: string;
  name?: string;
  url?: string;
  moveOutDate?: string;
};

export type TUpdateAnimalNameDB = {
  animalId: string;
  name: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalNameUI = {
  id: string;
  value: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalSpeciesDB = {
  animalId: string;
  animalSpecies: { id?: string; value?: string };
  tenantResidencyId: string;
};
export type TUpdateAnimalSpeciesUI = {
  id: string;
  animalSpecies: TOptionTypeUi;
  tenantResidencyId: string;
};

export type TUpdateAnimalWeightDB = {
  animalId: string;
  unit: string;
  weight: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalWeightUI = {
  id: string;
  value: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalBirthDateDB = {
  animalId: string;
  birthDate: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalBirthDateUI = {
  id: string;
  value: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalMicrochipDateDB = {
  animalId: string;
  microchip: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalMicrochipDateUI = {
  id: string;
  value: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalReproductiveDB = {
  animalId: string;
  reproductive: boolean;
  tenantResidencyId: string;
};
export type TUpdateAnimalReproductiveUI = {
  id: string;
  value: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalHouseTrainedDB = {
  animalId: string;
  houseTrained: boolean;
  tenantResidencyId: string;
};
export type TUpdateAnimalHouseTrainedUI = {
  id: string;
  value: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalGenderDB = {
  animalId: string;
  gender: EGender;
  tenantResidencyId: string;
};
export type TUpdateAnimalGenderUI = {
  id: string;
  value: EGenderUi;
  tenantResidencyId: string;
};

export type TUpdateAnimalBreedDB = {
  animalId: string;
  animalBreed: { id?: string; value?: string };
  tenantResidencyId: string;
};
export type TUpdateAnimalBreedUI = {
  id: string;
  breed: TOptionTypeUi;
  tenantResidencyId: string;
};

export type TUpdateAnimalAggressiveBehaviorDB = {
  animalId: string;
  aggressiveBehavior: { acted: boolean; incidentDetails?: string };
  tenantResidencyId: string;
};
export type TUpdateAnimalAggressiveBehaviorUI = {
  id: string;
  aggressiveBehavior: { acted: boolean; incidentDetails?: string };
  tenantResidencyId: string;
};

export type TUpdateAnimalTrainedForDB = {
  animalId: string;
  trainedFor: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalTrainedForUI = {
  id: string;
  trainedFor: string;
  tenantResidencyId: string;
};

export type TUpdateAnimalColorsDB = {
  animalId: string;
  primaryColor?: {
    value?: string;
    id?: string | null;
  };
  secondaryColor1?: string;
  secondaryColor2?: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalColorsUI = {
  id: string;
  primaryColor?: TOptionTypeUi;
  secondaryColor1?: TOptionTypeUi;
  secondaryColor2?: TOptionTypeUi;
  tenantResidencyId: string;
};

export type TUpdateAnimalProfilePictureDB = {
  animalId: string;
  profilePicture: string;
  tenantResidencyId: string;
};
export type TUpdateAnimalProfilePictureUI = {
  id: string;
  profilePicture: string;
  tenantResidencyId: string;
};

export type TVaccinationTypeDB = {
  predefined: boolean;
  value: string;
};
export type TVaccinationTypeUI = {
  predefined?: boolean;
  value?: string;
};

export type TVaccinationExpirationDB = {
  expirable: boolean;
  date?: string;
  expired: boolean;
};

export type TVaccinationExpirationUI = {
  expirable: boolean;
  date: string;
  expired: boolean;
};

export type TChangedRecordsTypeDB = {
  predefined?: string;
  custom?: string;
  value?: string;
};

export type TChangedRecordsTypeUI = {
  predefined?: string;
  custom?: string;
  value?: string;
};

export type TVaccinationRecordDB = {
  type: TVaccinationTypeDB;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};

export type TVaccinationRecordUI = {
  type: TVaccinationTypeUI;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};
export type TNewVaccinationRecordDB = {
  type: TChangedRecordsTypeDB;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};

export type TNewVaccinationRecordUI = {
  type: TChangedRecordsTypeUI;
  expiration: TVaccinationExpirationDB | null;
  id?: string;
  isExpired?: boolean;
};

export type TVetRecordsDB = {
  type: TVetRecordTypeUI;
  expiration?: TExpirationDateType;
};
export type TVetRecordsUI = {
  type: TVetRecordTypeUI;
  expiration?: TExpirationDateType;
};

export type TAnimalVetRecordCreateUi = {
  animalId: string;
  files: TFileDataType[];
  docName: string;
  records: TVetRecordsUI[];
  tenantResidencyId: string;
};

export type TAnimalVetRecordCreate = {
  animalId: string;
  records: TVetRecordsDB[];
  files: TFileDataType[];
  docName: string;
  tenantResidencyId: string;
};

export type TVetRecordDocumentsDB = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFileDataType[];
  removedDocumentsId: string[];
  removedRecordIds: string[];
  newRecords: TNewVaccinationRecordDB[];
  changedRecords: TNewVaccinationRecordDB[];
  tenantResidencyId: string;
};

export type TVetRecordDocumentsUI = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFileDataType[];
  removedDocumentsId: string[];
  removedRecordIds: string[];
  newRecords: TVaccinationRecordUI[];
  changedRecords: TVaccinationRecordUI[];
  tenantResidencyId: string;
};

export type TVetRecordDeleteUI = {
  animalId: string;
  vetRecordId: string;
  tenantResidencyId: string;
};

export type TVetRecordDeleteDB = {
  animalId: string;
  vetRecordId: string;
  tenantResidencyId: string;
};

export type TResidentAnimalScreeningUI = {
  tenantResidencyId: string;
  animalResidencyId: string;
  needToScreen: boolean;
};

export type TResidentAnimalScreeningDB = {
  tenantResidencyId: string;
  animalResidencyId: string;
  needToScreen: boolean;
};

export type TConditionalMovedInApprovedUI = {
  tenantResidencyId: string;
  animalResidencyId: string;
};

export type TConditionalMovedInApprovedDB = {
  tenantResidencyId: string;
  animalResidencyId: string;
};

export type TDownloadAnimalDocumnetsDB = {
  tenantResidencyId: string;
  animalId: string;
  hpDocumentPacketIds: string[];
  vetRecordIds: string[];
  saDocumentPacketIds: string[];
};

export type TDownloadAnimalDocumnetsUI = {
  tenantResidencyId: string;
  animalId: string;
  documnets: TSelectedPacket[];
  animalName: string;
};
