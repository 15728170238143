import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EResidenceUrl } from 'antd/data/urls/residence.url';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import {
  EAnimalStatusDB,
  TAddedPetsDB,
  TAddedPetsUI,
  TAnimalBreedDB,
  TAnimalBreedUI,
  TAnimalColorsDB,
  TAnimalColorsUI,
  TAnimalDB,
  TAnimalDetail,
  TAnimalDetailUi,
  TAnimalSpecieDB,
  TAnimalSpecieUI,
  TAnimalsStatisticsDB,
  TAnimalsStatisticsUI,
  TAnimalTerminateDB,
  TAnimalTerminateUI,
  TAnimalUI,
  TAnimalVetRecordCreateUi,
  TCheckDuplicateAnimalUI,
  TConditionalMovedInApprovedUI,
  TCreateAnimalResponseDB,
  TCreateAnimalResponseUI,
  TCreateAnimalUI,
  TDownloadAnimalDocumnetsUI,
  TDuplicateAnimalDB,
  TDuplicateAnimalUI,
  TFollowUpAnimalItemUI,
  TPreventedAnimalDB,
  TPreventedAnimalUI,
  TResidentAnimalScreeningUI,
  TUpdateAnimalAggressiveBehaviorUI,
  TUpdateAnimalBirthDateUI,
  TUpdateAnimalBreedUI,
  TUpdateAnimalColorsUI,
  TUpdateAnimalGenderUI,
  TUpdateAnimalHouseTrainedUI,
  TUpdateAnimalMicrochipDateUI,
  TUpdateAnimalNameUI,
  TUpdateAnimalProfilePictureUI,
  TUpdateAnimalReproductiveUI,
  TUpdateAnimalSpeciesUI,
  TUpdateAnimalTrainedForUI,
  TUpdateAnimalWeightUI,
  TVetRecordDeleteUI,
  TVetRecordDocumentsUI,
} from 'antd/data/types/animals.types';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import {
  addedPetsConverter,
  animalBreedConvertor,
  animalColorConvertor,
  animalConverter,
  animalSpecieConvertor,
  animalTerminateConverter,
  conditionalMovedInApproveConverter,
  createAnimalConvertor,
  createAnimalResponseConverter,
  deleteVetRecordConvertor,
  downloadAnimalDocumnetsParamsConverter,
  duplicateAnimalConverter,
  followUpAnimalConvertor,
  getAnimalConverter,
  getAnimalStatisticsConverter,
  preventedAnimalConverter,
  residentAnimalScreening,
  updateAnimalAggressiveBehaviorConvertor,
  updateAnimalBirthDateConvertor,
  updateAnimalBreedConvertor,
  updateAnimalColorsConvertor,
  updateAnimalGenderConvertor,
  updateAnimalHouseTrainedConvertor,
  updateAnimalMicrochipConvertor,
  updateAnimalNameConvertor,
  updateAnimalProfilePictureConvertor,
  updateAnimalReproductiveConvertor,
  updateAnimalSpeciesConvertor,
  updateAnimalTrainedForConvertor,
  updateAnimalWeightConvertor,
  updateVaccinationRecordsConverter,
} from 'antd/data/converters/animals.converter';
import { EApplicationUrls } from 'antd/data/urls/application.url';
import { downloadFile } from 'antd/api/sevices/fileServices';
import { ETenantUrl } from 'antd/data/urls/tenant.url';
import { EAnimalUrl } from 'antd/data/urls/animal.url';
import toast from 'antd/components/Notification';
import { EText } from 'antd/data/datas/genericData';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCE}`;
const animalUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EAnimalUrl.ANIMALS}`;

export const animalApi = authSplitApi('animalApi', [
  'animal',
  'animals',
  'terminatedAnimals',
  'applicantAnimals',
  'addedPets',
  'preventedAnimals',
  'currentAnimals',
  'dashboardStatistics',
  'dashboardStatisticsNotChargedPets',
]).injectEndpoints({
  endpoints: (build) => ({
    getAnimals: build.query<
      TPageableDataWithContentUi<TAnimalUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            statuses: [
              EAnimalStatusDB.MOVED_IN,
              EAnimalStatusDB.CONDITIONALLY_MOVED_IN,
            ],
          }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => animalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['animals'], // for caching and update cache
    }),
    getAllAnimals: build.query<
      TPageableDataWithContentUi<TAnimalUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.ALL}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            // hidePendingAnimalsBeingApproved: 'true',
          }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => animalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['animals'],
    }),
    getEmployeeAnimals: build.query<
      TPageableDataWithContentUi<TAnimalUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.ALL}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            hidePendingAnimalsBeingApproved: 'true',
            isEmployeeAnimal: 'true',
          }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => animalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['animals'],
    }),
    getAnimal: build.query<TAnimalDetailUi, string>({
      query(id) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMAL}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['animal'],
      transformResponse: (data: TAnimalDetail) =>
        getAnimalConverter.fromDb(data),
    }),
    getAnimalStatistics: build.query<TAnimalsStatisticsUI, void>({
      query() {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.STATISTICS}/${EResidenceUrl.DASHBOARD}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TAnimalsStatisticsDB) =>
        getAnimalStatisticsConverter.fromDb(data),
    }),
    getTerminatedAnimals: build.query<
      TPageableDataWithContentUi<TAnimalUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            statuses: EAnimalStatusDB.MOVED_OUT,
          }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => animalConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['terminatedAnimals'], // for caching and update cache
    }),
    terminateAnimal: build.mutation<TAnimalTerminateDB, TAnimalTerminateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.TERMINATE}`,
          method: 'PATCH',
          body: animalTerminateConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'terminatedAnimals'],
    }),
    getApplicantAnimals: build.query<
      TPageableDataWithContentUi<TAnimalUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EApplicationUrls.ANIMALS}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            statuses: [
              EAnimalStatusDB.PENDING,
              EAnimalStatusDB.CONDITIONALLY_MOVED_IN,
              EAnimalStatusDB.CONDITIONALLY_APPROVED,
            ],
            sort: 'PENDING_APPROVAL_DATE,desc',
          }),
        };
      },
      providesTags: ['applicantAnimals'],
      transformResponse: (data: TPageableDataWithContent<TAnimalDB[]>) => {
        const { content, ...other } = data;
        return {
          content: (data?.content || []).map((item) =>
            animalConverter.fromDb(item)
          ),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
    getAddedPets: build.query<
      TPageableDataWithContentUi<TAddedPetsUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.ADDED_PETS}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}),
        };
      },
      providesTags: ['addedPets'],
      transformResponse: (data: TPageableDataWithContent<TAddedPetsDB[]>) => {
        const { content, ...other } = data;
        return {
          content: (data?.content || []).map((item) =>
            addedPetsConverter.fromDb(item)
          ),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
    getPreventedAnimals: build.query<
      TPageableDataWithContentUi<TPreventedAnimalUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.PREVENTED}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}),
        };
      },
      providesTags: ['preventedAnimals'],
      transformResponse: (
        data: TPageableDataWithContent<TPreventedAnimalDB[]>
      ) => {
        const { content, ...other } = data;
        return {
          content: (data?.content || []).map((item) =>
            preventedAnimalConverter.fromDb(item)
          ),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
    applicantAnimalsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EApplicationUrls.ANIMALS}/${EApplicationUrls.PENDING_APPROVAL}/${EApplicationUrls.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            statuses: [
              EAnimalStatusDB.PENDING,
              EAnimalStatusDB.CONDITIONALLY_MOVED_IN,
              EAnimalStatusDB.CONDITIONALLY_APPROVED,
            ],
          }),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'applicant animals.csv');
            return null;
          },
        };
      },
    }),
    currentAnimalsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            statuses: [
              EAnimalStatusDB.MOVED_IN,
              EAnimalStatusDB.CONDITIONALLY_MOVED_IN,
            ],
          }),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'current animals.csv');
            return null;
          },
        };
      },
      providesTags: ['currentAnimals'],
    }),
    allAnimalsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.ALL}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            hidePendingAnimalsBeingApproved: 'true',
          }),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'all animals.csv');
            return null;
          },
        };
      },
    }),
    employeeAnimalsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.ALL}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            hidePendingAnimalsBeingApproved: 'true',
            isEmployeeAnimal: 'true',
          }),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'employee animals.csv');
            return null;
          },
        };
      },
    }),
    addedPetsExportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.ADDED_PETS}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {
            hidePendingAnimalsBeingApproved: 'true',
          }),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'added pets.csv');
            return null;
          },
        };
      },
    }),
    checkDuplicateAnimal: build.query<
      TDuplicateAnimalUI | null,
      TCheckDuplicateAnimalUI
    >({
      query(params) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.DUPLICATIONS}`,
          method: 'GET',
          params: { ...params },
        };
      },
      transformResponse: (data: TDuplicateAnimalDB[]) => {
        return data.length ? duplicateAnimalConverter.fromDb(data[0]) : null;
      },
    }),
    removePreventedAnimal: build.mutation<void, { id: string; reason: string }>(
      {
        query(body) {
          return {
            url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.PREVENTED}`,
            method: 'DELETE',
            body,
          };
        },
        invalidatesTags: ['preventedAnimals'],
      }
    ),
    getAnimalSpecies: build.query<TAnimalSpecieUI[], void>({
      query() {
        return {
          url: `${ETenantUrl.TENANT}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.SPECIES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TAnimalSpecieDB[]) => {
        return data.map((item) => animalSpecieConvertor.fromDb(item));
      },
    }),
    getAnimalColors: build.query<TAnimalColorsUI[], void>({
      query() {
        return {
          url: `${ETenantUrl.TENANT}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.COLORS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TAnimalColorsDB[]) => {
        return data.map((item) => animalColorConvertor.fromDb(item));
      },
    }),
    getAnimalBreed: build.query<TAnimalBreedUI[], { speciesId: string }>({
      query(params) {
        return {
          url: `${ETenantUrl.TENANT}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.BREEDS}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TAnimalBreedDB[]) => {
        return data.map((item) => animalBreedConvertor.fromDb(item));
      },
    }),
    createAniaml: build.mutation<TCreateAnimalResponseUI, TCreateAnimalUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EResidenceUrl.ANIMAL}`,
          method: 'PATCH',
          body: createAnimalConvertor.toDb(body),
        };
      },
      transformResponse: (data: TCreateAnimalResponseDB) => {
        return createAnimalResponseConverter.fromDb(data);
      },
    }),
    followUpAnimal: build.mutation<void, TFollowUpAnimalItemUI>({
      query(body) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EAnimalUrl.FOLLOW_UP}/${EAnimalUrl.ITEMS}`,
          method: 'POST',
          body: followUpAnimalConvertor.toDb(body),
        };
      },
    }),
    updateAnimalName: build.mutation<void, TUpdateAnimalNameUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.NAME}`,
          method: 'PATCH',
          body: updateAnimalNameConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalSpecies: build.mutation<void, TUpdateAnimalSpeciesUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.SPECIES}`,
          method: 'PATCH',
          body: updateAnimalSpeciesConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalWeight: build.mutation<void, TUpdateAnimalWeightUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.WEIGHT}`,
          method: 'PATCH',
          body: updateAnimalWeightConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalBirthDate: build.mutation<void, TUpdateAnimalBirthDateUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.BIRTHDATE}`,
          method: 'PATCH',
          body: updateAnimalBirthDateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalMicrochipDate: build.mutation<
      void,
      TUpdateAnimalMicrochipDateUI
    >({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.MICROCHIP}`,
          method: 'PATCH',
          body: updateAnimalMicrochipConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalReproductive: build.mutation<void, TUpdateAnimalReproductiveUI>(
      {
        query(body) {
          return {
            url: `${animalUrl}/${EAnimalUrl.REPRODUCTIVE}`,
            method: 'PATCH',
            body: updateAnimalReproductiveConvertor.toDb(body),
          };
        },
        invalidatesTags: ['animal'],
      }
    ),
    updateAnimalHouseTrained: build.mutation<void, TUpdateAnimalHouseTrainedUI>(
      {
        query(body) {
          return {
            url: `${animalUrl}/${EAnimalUrl.HOUSE_TRAINED}`,
            method: 'PATCH',
            body: updateAnimalHouseTrainedConvertor.toDb(body),
          };
        },
        invalidatesTags: ['animal'],
      }
    ),
    updateAnimalGender: build.mutation<void, TUpdateAnimalGenderUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.GEDNER}`,
          method: 'PATCH',
          body: updateAnimalGenderConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalBreed: build.mutation<void, TUpdateAnimalBreedUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.BREED}`,
          method: 'PATCH',
          body: updateAnimalBreedConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalAggressiveBehavior: build.mutation<
      void,
      TUpdateAnimalAggressiveBehaviorUI
    >({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.AGGRESSIVE_BEHAVIOR}`,
          method: 'PATCH',
          body: updateAnimalAggressiveBehaviorConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalTrainedFor: build.mutation<void, TUpdateAnimalTrainedForUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.TRAINED_FOR}`,
          method: 'PATCH',
          body: updateAnimalTrainedForConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalColors: build.mutation<void, TUpdateAnimalColorsUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.COLORS}`,
          method: 'PATCH',
          body: updateAnimalColorsConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateAnimalProfilePicture: build.mutation<
      void,
      TUpdateAnimalProfilePictureUI
    >({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.PHOTO}`,
          method: 'PATCH',
          body: updateAnimalProfilePictureConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    attachVetRecord: build.mutation<void, TAnimalVetRecordCreateUi>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.ATTACH_VET_RECORD}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['animal'],
    }),
    updateVetRecord: build.mutation<void, TVetRecordDocumentsUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.VET_RECORD}`,
          method: 'PUT',
          body: updateVaccinationRecordsConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    deleteVetRecord: build.mutation<void, TVetRecordDeleteUI>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.VET_RECORD}`,
          method: 'DELETE',
          body: deleteVetRecordConvertor.toDb(body),
        };
      },
      invalidatesTags: ['animal'],
    }),
    getPetNotChargedCount: build.query<{ count: number | null }, void>({
      query(body) {
        return {
          url: `${animalUrl}/${EAnimalUrl.STATISTICS}/${EAnimalUrl.DASHBOARD}/${EAnimalUrl.NOT_CHARGED_PETS}`,
          method: 'GET',
        };
      },
      providesTags: ['dashboardStatisticsNotChargedPets'],
    }),
    residentAnimalScreening: build.mutation<string, TResidentAnimalScreeningUI>(
      {
        query(body) {
          return {
            url: `${baseUrl}/${EAnimalUrl.ANIMALS}/${EAnimalUrl.SCREENING}`,
            method: 'PATCH',
            body: residentAnimalScreening.toDb(body),
          };
        },
        invalidatesTags: ['animal'],
      }
    ),
    conditionalMovedInApprove: build.mutation<
      string,
      TConditionalMovedInApprovedUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EAnimalUrl.ANIMALS}/${EAnimalUrl.CONDITIONAL_MOVED_IN}/${EAnimalUrl.APPROVE}`,
          method: 'PATCH',
          body: conditionalMovedInApproveConverter.toDb(body),
        };
      },
      invalidatesTags: ['animal', 'animals', 'currentAnimals', 'addedPets'],
    }),
    downloadAnimalDocuments: build.query<string, TDownloadAnimalDocumnetsUI>({
      query(params) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.DOCUMENTS_DOWNLOAD}`,
          method: 'GET',
          params: downloadAnimalDocumnetsParamsConverter.toDb(params),
          responseHandler: async (response) => {
            if (response.status === 200) {
              const fileName = `${params.animalName || 'animal'}_documents`;
              const file = await response.blob();
              downloadFile(file, fileName, 'pdf');
              return null;
            }
            const error = await response.json();
            toast({
              description: error.message || EText.DEFAULT_ERROR,
              type: 'error',
            });
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPetNotChargedCountQuery,
  useGetAnimalsQuery,
  useGetAllAnimalsQuery,
  useGetAnimalQuery,
  useGetAnimalStatisticsQuery,
  useGetTerminatedAnimalsQuery,
  useGetAddedPetsQuery,
  useGetPreventedAnimalsQuery,
  useTerminateAnimalMutation,
  useGetApplicantAnimalsQuery,
  useLazyCurrentAnimalsExportCSVQuery,
  useLazyApplicantAnimalsExportCSVQuery,
  useLazyAllAnimalsExportCSVQuery,
  useLazyCheckDuplicateAnimalQuery,
  useRemovePreventedAnimalMutation,
  useGetAnimalSpeciesQuery,
  useGetAnimalColorsQuery,
  useGetAnimalBreedQuery,
  useCreateAniamlMutation,
  useFollowUpAnimalMutation,
  useLazyAddedPetsExportCSVQuery,
  useUpdateAnimalNameMutation,
  useUpdateAnimalSpeciesMutation,
  useUpdateAnimalWeightMutation,
  useUpdateAnimalBirthDateMutation,
  useUpdateAnimalMicrochipDateMutation,
  useUpdateAnimalReproductiveMutation,
  useUpdateAnimalHouseTrainedMutation,
  useUpdateAnimalGenderMutation,
  useUpdateAnimalBreedMutation,
  useUpdateAnimalAggressiveBehaviorMutation,
  useUpdateAnimalTrainedForMutation,
  useUpdateAnimalColorsMutation,
  useUpdateAnimalProfilePictureMutation,
  useAttachVetRecordMutation,
  useUpdateVetRecordMutation,
  useDeleteVetRecordMutation,
  useResidentAnimalScreeningMutation,
  useConditionalMovedInApproveMutation,
  useLazyDownloadAnimalDocumentsQuery,
  useGetEmployeeAnimalsQuery,
  useLazyEmployeeAnimalsExportCSVQuery,
} = animalApi;
